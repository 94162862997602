import Home from "../Home/Home";
import classes from "../Table/Table.module.css";
import axios from "axios";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import BuildingTable from "../BuildingTable/BuildingTable";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
const base_url = process.env.REACT_APP_BASE_URL;
const BuildingWrapper = () => {
  const activeBuilding = useSelector((state) => state.appData.activeBuilding);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [isLoading, setIsloading] = useState(false);
  const [buildingToDelete, setBuildingToDelete] = useState({});

  const handleEdit = (id) => {
    dispatch(appActions.setActiveEditLocationId(id));
    localStorage.setItem("activeEditLocationId", id);

    dispatch(appActions.setActiveEditType("Company"));
    localStorage.setItem("activeEditType", "Company");
    navigate("/editLocation");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/company/building/company/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const columns = [
    {
      name: "S. No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Company Name",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Company Manager Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Company Manager Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Company Manager Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            {/* <img src={require("../../assets/img/eye.png")} /> */}
            <img
              style={{ cursor: "pointer" }}
              src={require("../../assets/img/edit_thin.png")}
              onClick={() => handleEdit(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../assets/img/delete.png")}
              // onClick={() => handleDelete(row._id)}
              onClick={() =>
                setBuildingToDelete({ id: row._id, name: row.location })
              }
            />
          </div>
        </>
      ),
    },
  ];
  const options = {
    // title: activeCompany.name,
    title: "Building",
    showCsvExport: false,
    showDownload: false,
    showExport: false,
    showLocation: false,
    showSms: true,
    showAddNew: true,
    showFirstHr: false,
    showSecondHr: true,
    showActions: true,
    type: "Building",
    columns: columns,
    apiUrl: `${base_url}/company/building/company/list`,
    companyId: activeBuilding._id,
    locationId: activeLocation._id,
    showVisitor: true,
  };

  return (
    <>
      <Home
        propComponent={
          <BuildingTable options={options} isLoading={isLoading} />
        }
      />
      <ConfirmModal
        handleDelete={handleDelete}
        id={buildingToDelete.id}
        msg={`Are you sure you want to delete ${buildingToDelete.name}?`}
      />
    </>
  );
};

export default BuildingWrapper;
