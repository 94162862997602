import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passwordId: localStorage.getItem("passwordId"),
  activeCompany: JSON.parse(localStorage.getItem("activeCompany")),
  activeCompanyDetails: JSON.parse(
    localStorage.getItem("activeCompanyDetails")
  ),
  activeBuilding: JSON.parse(localStorage.getItem("activeBuilding")),
  activeLocation: JSON.parse(localStorage.getItem("activeLocation")) || [],
  activeVisitor: JSON.parse(localStorage.getItem("activeVisitor")),
  activeEditEmployeeId: localStorage.getItem("activeEditEmployeeId"),
  activeEditCompanyId: localStorage.getItem("activeEditCompanyId"),
  activeEditSubadminId: localStorage.getItem("activeEditSubadminId"),
  activeEditLocationId: localStorage.getItem("activeEditLocationId"),
  addCompanyOrBuilding: "",
  showEmployeeDirectory: localStorage.getItem("showEmployeeDirectory"),
  resetPhone: Number(localStorage.getItem("resetPhone")),
  resetId: localStorage.getItem("resetId"),
  activeEditType: localStorage.getItem("activeEditType"),
  sortDirection: "-1",
  barChartPayload: JSON.parse(localStorage.getItem("barChartPayload")),
  lineChartPayload: JSON.parse(localStorage.getItem("lineChartPayload")),
  barChartFreqency: 7,
  lineChartFreqency: 7,
};
export const appDataSlice = createSlice({
  name: "appDataSlice",
  initialState,
  reducers: {
    setPasswordId(state, action) {
      state.passwordId = action.payload;
    },
    setActiveCompany(state, action) {
      state.activeCompany = action.payload;
    },
    setActiveCompanyDetails(state, action) {
      state.activeCompanyDetails = action.payload;
    },
    setActiveBuilding(state, action) {
      state.activeBuilding = action.payload;
    },
    setActiveLocation(state, action) {
      state.activeLocation = action.payload;
    },
    setActiveEditEmployeeId(state, action) {
      state.activeEditEmployeeId = action.payload;
    },
    setActiveEditCompanyId(state, action) {
      state.activeEditCompanyId = action.payload;
    },
    setActiveEditSubadminId(state, action) {
      state.activeEditSubadminId = action.payload;
    },
    setActiveEditLocationId(state, action) {
      state.activeEditLocationId = action.payload;
    },
    setActiveVisitor(state, action) {
      state.activeVisitor = action.payload;
    },
    setAddCompanyOrBuilding(state, action) {
      state.addCompanyOrBuilding = action.payload;
    },
    setShowEmployeeDirectory(state, action) {
      state.showEmployeeDirectory = action.payload;
    },
    setResetPhone(state, action) {
      state.resetPhone = action.payload;
    },
    setResetId(state, action) {
      state.resetId = action.payload;
    },
    setActiveEditType(state, action) {
      state.activeEditType = action.payload;
    },
    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },
    setBarChartPayload(state, action) {
      state.barChartPayload = action.payload;
    },
    setLineChartPayload(state, action) {
      state.lineChartPayload = action.payload;
    },
    setBarChartFreqency(state, action) {
      state.barChartFreqency = action.payload;
    },
    setLineChartFreqency(state, action) {
      state.lineChartFreqency = action.payload;
    },
  },
});

const appActions = appDataSlice.actions;

export default appActions;
