import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import classes from "./Selfie.module.css";
import "../../assets/css/master.css";
import { toast } from "react-toastify";
const Selfie = () => {
  const selfieImg = useSelector((state) => state.visitorData.selfie);
  const facingMode = useSelector((state) => state.visitorData.facingMode);
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState();
  const navigate = useNavigate();

  // let DeviceDetails = navigator.userAgent;
  // console.log(DeviceDetails);
  useEffect(() => {
    let submit_failed = localStorage.getItem("submit_failed");
    if (submit_failed == "True") {
      toast.error("Internet was not stable, please retry", {
        position: "top-center",
      });
      localStorage.removeItem("submit_failed");
    }
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [selfieImg]);

  const handleSubmit = () => {
    if (selfieImg) {
      navigate("/govt-id");
    } else {
      setErrMsg("Please click a selfie");
    }
  };

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "none" }}>
            <div className="selfie-panel">
              <div className="selfietop-panel">
                <h2>Please take your selfie to proceed further</h2>
              </div>
              <div className="selfie-bootom">
                <div className="camara-pic">
                  {!selfieImg && (
                    <img
                      src={require("../../assets/img/images/selfie-img.png")}
                    />
                  )}
                  {selfieImg && (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        "--webkit-transform":
                          facingMode == "user" ? "scaleX(-1)" : "scaleX(1)",
                        transform:
                          facingMode == "user" ? "scaleX(-1)" : "scaleX(1)",
                      }}
                      src={selfieImg.base64}
                    />
                  )}
                </div>

                <span className={classes.errMsg}>{errMsg}</span>
                <div className={classes.camera}>
                  <div>
                    <Link
                      to="/camera"
                      onClick={() =>
                        dispatch(visitorActions.setActiveCamera("selfie"))
                      }
                    >
                      <img src={require("../../assets/img/camera.png")} />
                    </Link>
                  </div>
                </div>
                <div className={classes.btn}>
                  <button
                    className={classes.back}
                    onClick={() => navigate("/visitor-details")}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className={classes.next}
                    onClick={handleSubmit}
                  >
                    Next
                    {/* <Link to="/govt-id">Next</Link> */}
                  </button>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Selfie;
