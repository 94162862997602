import axios from "axios";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import PopupDropdown from "../PopupDropdown/PopupDropdown";
import Loaderspinner from "./Loaderspinner";
import CustomMaterialPagination from "./PaginationComponent/PaginationComponent";
import classes from "./Table.module.css";
import { toast } from "react-toastify";

function DatatableLoader() {
  return (
    <>
      <div style={{ padding: "40px" }}>
        <Loaderspinner size="45px" />
      </div>
    </>
  );
}

const tableTop = (
  <>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2>Title</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Employee Directory</p>
        <p>Location</p>
      </div>
    </div>
  </>
);

const Table = ({ options, isLoading }) => {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    title,
    showCsvExport,
    showDownload,
    showExport,
    showLocation,
    showSms,
    showAddNew,
    showFirstHr,
    showSecondHr,
    showActions,
    type,
    columns,
    apiUrl,
    companyId,
    locationId,
    showVisitor,
    employeeDirectorySwitch,
  } = options;

  const sortDirection = useSelector((state) => state.appData.sortDirection);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [sortByColumn, setSortByColumn] = useState("_id");
  const [sortByDir, setSortByDir] = useState("-1");
  const [isInitialize, setIsInitialize] = useState(false);
  const [locationList, setLocationList] = useState([]);
  let downloadLocationId = "";
  if (type === "EmployeeDirectory") {
    downloadLocationId = locationId;
  } else if (userType == "location") {
    downloadLocationId = locationId;
  } else {
    downloadLocationId = "";
  }
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);

  let postdata = {
    search: "",
    start: 0,
    limit: 10,
    sortBy: "_id",
    sortDir: sortDirection,
    companyId: companyId,
    locationId:
      activeLocation &&
      (activeLocation["_id"] === "All" ? "" : activeLocation["_id"]),
  };

  useEffect(() => {
    if (
      title === "Visitor" &&
      (userType === "admin" || userType === "subadmin")
    ) {
      dispatch(appActions.setActiveLocation({ _id: "All", location: "All" }));
      postdata = {
        ...postdata,
        locationId: locationId,
      };
    }
  }, [title]);

  const fetchApiData = async (
    page,
    size = perPage,
    sortBy = sortByColumn,
    sortDir = sortByDir,
    searchKw = filterText
  ) => {
    setLoading(true);

    postdata["start"] = (page - 1) * size;
    postdata["length"] = size;

    if (type == "EmployeeDirectory") {
      postdata["search"] = searchKw;
    } else {
      postdata["search"] = searchKw;
    }

    const response = await axios.post(apiUrl, postdata, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
    });

    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);

    setLoading(false);
    setIsInitialize(true);
  };

  useEffect(() => {
    fetchApiData(1).catch((err) => {
      console.log("TableError", err);
      setLoading(false);
    });
  }, [isLoading, title]);

  useEffect(() => {
    if (companyId) {
      getCompanyLocation(companyId);
    }
    fetchApiData(1).catch((err) => {
      console.log("TableError", err);
      setLoading(false);
    });
  }, []);

  let locationDropDown = [...new Set([...data])];
  const hiddenFileInput = useRef(null);
  const base_url = process.env.REACT_APP_BASE_URL;
  const getCompanyLocation = async (companyId) => {
    await axios
      .get(`${base_url}/visitor/company/location/${companyId}`)
      .then((resp) => {
        setLocationList(resp.data.location);
      })
      .catch(() => {});
  };

  async function downloadVisitorFile(id) {
    const { data } = await axios.post(
      `${base_url}/visitor/export`,
      {
        companyId: id,
        locationId: downloadLocationId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`,
        },
      }
    );
    return data;
  }

  async function downloadEmployeeFile(id) {
    const { data } = await axios.post(
      `${base_url}/company/employee/export`,
      { companyId: id, locationId: downloadLocationId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`,
        },
      }
    );
    return data;
  }

  const donwloadSmapleCsv = () => {
    window.open("https://standy.s3.amazonaws.com/employees.csv", "_blank");
  };
  const exportData = async (id) => {
    if (type == "Visitor") {
      let response = await downloadVisitorFile(id)
        .then((data) => {
          window.open(data.url, "_blank");
          if (data.url) {
          } else {
            //  alert("There is no data");
          }
        })
        .catch((err) => {
          toast.error(
            err.response.data.error.message || "Something went wrong!!!"
          );
        });
    } else if (title.includes("Employee")) {
      let response = await downloadEmployeeFile(id)
        .then((data) => {
          window.open(data.url, "_blank");
          if (data.url) {
          }
        })
        .catch((err) => {
          toast.error(
            err.response.data.error.message || "Something went wrong!!!"
          );
        });
    }
  };

  function uploadFile(event) {
    if (downloadLocationId === "All" || downloadLocationId === "") {
      toast.error("Please select location!!");
      hiddenFileInput.current.value = "";
      return;
    }
    let payload = {
      companyId: companyId,
      locationId: downloadLocationId,
      csvfile: event.target.files[0],
    };
    axios
      .post(`${base_url}/company/employee/import`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          toast.success("Data Uploaded Successfully!!");
          fetchApiData(1).catch((err) => {
            setLoading(false);
          });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!!");
      });
    hiddenFileInput.current.value = "";
  }

  const subHeaderComponentMemo = useMemo(() => {
    let list = [...locationList];
    return (
      <>
        <div className={classes.subHeader}>
          <div
            className={`form-group has-search m-2 ${classes.subHeaderSearch}`}
          >
            <span
              className={`fa fa-search form-control-feedback ${classes.searchIcon}`}
            >
              <img
                src={require("../../assets/img/search.png")}
                className="cursor-pointer"
              />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
                setCurrentPage(1);
                fetchApiData(
                  1,
                  perPage,
                  sortByColumn,
                  sortByDir,
                  e.target.value
                ).catch((err) => {
                  console.log("FilterFailed", err);
                  setLoading(false);
                });
              }}
              value={filterText}
            />
          </div>
          <div className={classes.subHeaderRight}>
            {showVisitor && (
              <div
                className={classes.subHeaderRightItem}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/visitors-list")}
              >
                <img src={require("../../assets/img/profile_logo.png")} />
                <p>Visitors</p>
              </div>
            )}
            {showCsvExport && (
              <div
                className={classes.subHeaderRightItem}
                onClick={() => {
                  donwloadSmapleCsv();
                }}
              >
                <img
                  src={require("../../assets/img/file.png")}
                  className="cursor-pointer"
                />
                <p>Download Sample CSV</p>
              </div>
            )}

            {showDownload && (
              <div className={classes.subHeaderRightItem}>
                <img
                  className="cursor-pointer"
                  onClick={() => exportData(companyId)}
                  src={require("../../assets/img/down.png")}
                />
              </div>
            )}

            {showFirstHr && <div className={classes.v1}></div>}

            {showExport && (
              <div className={classes.subHeaderRightItem}>
                <img
                  className="cursor-pointer"
                  src={require("../../assets/img/export.png")}
                  onClick={() => hiddenFileInput.current.click()}
                />
                <input
                  type="file"
                  id="Identity-Proof"
                  name="file"
                  className="upload-doc"
                  accept={".csv,.xlsx,.xml"}
                  ref={hiddenFileInput}
                  hidden
                  onChange={(e) => {
                    uploadFile(e);
                  }}
                />
              </div>
            )}
            {
              // console.log("LOCATION ID", locationId)
            }
            {showLocation && (
              <div className={classes.subHeaderRightItem}>
                <div className={classes.iconDiv}>
                  <img src={require("../../assets/img/location.png")} />
                </div>
                <select
                  key={locationList}
                  defaultValue={locationId}
                  onChange={(e) => {
                    if (e.target.value !== "All") {
                      dispatch(
                        appActions.setActiveLocation({
                          _id: e.target.value,
                          location:
                            list[
                              list.findIndex((item) => {
                                return item._id === e.target.value;
                              })
                            ]["location"],
                        })
                      );
                      localStorage.setItem(
                        "activeLocation",
                        JSON.stringify({
                          _id: e.target.value,
                          location:
                            list[
                              list.findIndex((item) => {
                                return item._id === e.target.value;
                              })
                            ]["location"],
                        })
                      );
                    } else {
                      dispatch(
                        appActions.setActiveLocation({
                          _id: "All",
                          location: "All",
                        })
                      );
                      localStorage.setItem(
                        "activeLocation",
                        JSON.stringify({
                          _id: "All",
                          location: "All",
                        })
                      );
                    }
                    postdata = {
                      ...postdata,
                      locationId:
                        e.target.value === "All" ? "" : e.target.value,
                    };

                    let currLocation =
                      e.target.value === "All" ? "" : e.target.value;
                    downloadLocationId = currLocation;
                    setCurrentPage(1);
                    fetchApiData(1, perPage, sortByColumn, sortByDir).catch(
                      (err) => {
                        setLoading(false);
                      }
                    );
                  }}
                >
                  <option value="All">All</option>
                  {list.length > 0 &&
                    list.map((item) => {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.location}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}

            {showSecondHr && <div className={classes.v1}></div>}

            {showSms && (
              <div
                className={classes.subHeaderRightItem}
                onClick={() => navigate("/smsSetting")}
                style={{ cursor: "pointer" }}
              >
                <div className={classes.iconDiv}>
                  <img src={require("../../assets/img/sms_blue.png")} />
                </div>
                <p>SMS Settings</p>
              </div>
            )}

            {showAddNew && <PopupDropdown type={type} />}
          </div>
        </div>
      </>
    );
  }, [filterText, locationList]);

  const handlePageChange = (page) => {
    if (isInitialize !== false) {
      fetchApiData(page).catch((err) => {
        setLoading(false);
      });
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = (newPerPage, page) => {
    if (isInitialize !== false) {
      fetchApiData(page, newPerPage).catch((err) => {
        setLoading(false);
      });
      setPerPage(newPerPage);
    }
  };

  const handleSort = (column, sortDirection) => {
    if ("id" in column && isInitialize !== false) {
      let sortIndex = column.id - 1;
      setSortByDir(sortDirection);
      setSortByColumn(sortIndex);
      fetchApiData(currentPage, perPage, sortIndex, sortByDir).catch((err) => {
        setLoading(false);
      });
    }
  };

  const handleRowClicked = (row) => {
    if (row.type == "Building") {
      dispatch(appActions.setActiveBuilding(row));
      localStorage.setItem("activeBuilding", JSON.stringify({ ...row }));

      dispatch(appActions.setActiveCompany(row));
      localStorage.setItem("activeCompany", JSON.stringify({ ...row }));
      navigate("/building");
    } else if (type == "Companies") {
      dispatch(appActions.setActiveCompany(row));
      localStorage.setItem("activeCompany", JSON.stringify({ ...row }));
      navigate("/location");
    } else if (type == "Location") {
      if (!employeeDirectorySwitch) {
        return;
      }
      dispatch(appActions.setActiveLocation(row));
      localStorage.setItem("activeLocation", JSON.stringify({ ...row }));
      navigate("/employeeDirectory");
    } else if (type == "Visitor") {
      dispatch(appActions.setActiveVisitor(row));
      localStorage.setItem("activeVisitor", JSON.stringify({ ...row }));
      navigate("/companies/company/visitor-details");
    }
  };

  let showActionTerms = userType !== "location";
  let hideActionLocation =
    userType === "location" ||
    userType === "company" ||
    userType === "Building Manager" ||
    (activeCompany && activeCompany["type"] === "Building");

  const tableActions = (
    <>
      {showActionTerms && (
        <div className={classes.tableAction}>
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/terms")}>
            <img src={require("../../assets/img/copy.png")} />
            <span>Terms</span>
          </div>
        </div>
      )}
      {!hideActionLocation && (
        <div className={classes.tableAction}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/location")}
          >
            <img src={require("../../assets/img/company_black.png")} />
            <span>Location</span>
          </div>
        </div>
      )}
    </>
  );

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      progressPending={loading}
      progressComponent={<DatatableLoader />}
      defaultSortFieldId="_id"
      defaultSortAsc={false}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      paginationPerPage={10}
      fixedHeader
      fixedHeaderScrollHeight="300px"
      onRowClicked={handleRowClicked}
      conditionalRowStyles={[
        {
          when: (row) => true,
          style: {
            backgroundColor: "#ffffff",
            margin: "8px",
            boxShadow: "0px 0px 1px #707070",
            color: "#707070",
            "&:hover": {
              cursor: "pointer",
            },
            cursor: "pointer",
          },
        },
      ]}
      actions={showActions ? tableActions : ""}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      subHeaderAlign="left"
    />
  );
};

export default Table;
