import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import Home from "../Home/Home";
import Table from "../Table/Table";
import classes from "../Table/Table.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const VisitorWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  console.log("Context value in visitor wrapper", ctx);
  const userType = ctx.userData.user_type;
  const sortDirection = useSelector((state) => state.appData.sortDirection);
  const dateSort = (rowA, rowB) => {
    dispatch(appActions.setSortDirection(sortDirection === "1" ? "-1" : "1"));
    localStorage.setItem("sortDirection", sortDirection === "1" ? "-1" : "1");
  };

  const columns = [
    {
      id: "srNo",
      name: "Visitor ID",
      cell: (row, index) => row._id,
    },
    {
      id: "date",
      name: "Request Date & Time",
      selector: (row) => {
        const formatedDate = moment(row.createdAt).format("MMM D, hh:mm A");
        return formatedDate;
      },
      sortable: false,
      sortFunction: dateSort,
    },
    {
      name: "User/Company Name",
      selector: (row) => `${row.firstName}-${row.companyName}`,
      sortable: true,
    },
    {
      name: "Meeting Person/Designation",
      selector: (row) => `${row.empName}-${row.empDesignation}`,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Meeting Purpose",
      selector: (row) => row.purposeOfVisit,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  let showCsvExport = false;
  let showDownload = true;
  let showExport = false;
  let showLocation = true;
  let showSms = true;
  let showAddNew = false;
  let showFirstHr = false;
  let showSecondHr = false;
  let showActions = true;

  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager"
  ) {
    showCsvExport = false;
    showDownload = true;
    showExport = false;
    showLocation = false;
    showSms = false;
    showAddNew = false;
    showFirstHr = false;
    showSecondHr = false;
    showActions = false;
  }
  if (userType === "Building Manager") {
    showLocation = true;
  }

  const options = {
    title: "Visitor",
    showCsvExport: showCsvExport,
    showDownload: showDownload,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    type: "Visitor",
    columns: columns,
    apiUrl: `${base_url}/visitor/list`,
    companyId: activeCompany._id, //for building company Id and Building Id is same
    locationId: userType === "location" ? activeLocation._id : "",
  };
  return (
    <>
      <Home propComponent={<Table options={options} />} />
    </>
  );
};

export default VisitorWrapper;
