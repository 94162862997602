import AddNewSubAdmin from "./AddNewSubAdmin";
import Home from "../Home/Home";

const AddSubAdminWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewSubAdmin />} />
    </>
  );
};

export default AddSubAdminWrapper;
