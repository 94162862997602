import classes from "../AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import React, { useContext, useEffect, useState } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import authContext from "../../../AuthContext/authContext";
import axios from "axios";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const Terms = ({ initialData }) => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(initialData.terms || "")
      )
    )
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const handleSubmit = () => {
    console.log("Editor text", convertToHTML(editorState.getCurrentContent()));
    if (convertToHTML(editorState.getCurrentContent()) == "<p></p>") {
      toast.error("Please enter terms and conditions");
      return;
    }
    let reqObj = {
      method: "POST",
      url: `${base_url}/company/terms/save`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        companyId: activeCompany._id,
        terms: convertToHTML(editorState.getCurrentContent()),
      },
    };

    axios(reqObj)
      .then((res) => {
        console.log(res);
        toast.success("Terms and conditions updated successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.response.data.error.message ||
            "Failed to update terms and conditions"
        );
      });
  };

  return (
    <>
      <div className={classes.mainCont}>
        <h3>Terms & Conditions</h3>

        <section className={classes.Terms}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorStyle={{
              backgroundColor: "#FFFFFF",
            }}
            editorClassName={classes.Editor}
            toolbarClassName="toolbar-class"
          />
        </section>

        <div className={classes.formBtn}>
          <button className={classes.createBtn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Terms;
