import AddNewCompany from "./AddNewCompany";
import Home from "../Home/Home";

const AddNewCompanyWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewCompany />} />
    </>
  );
};

export default AddNewCompanyWrapper;
