import Home from "../Home/Home";
import Table from "../Table/Table";
import classes from "../Table/Table.module.css";
import axios from "axios";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import { useEffect } from "react";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const LocationWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const token = ctx.token;
  const [employeeDirectory, setShowEmployeeDirectory] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState({});

  const handleEdit = (id) => {
    dispatch(appActions.setActiveEditLocationId(id));
    localStorage.setItem("activeEditLocationId", id);
    dispatch(appActions.setActiveEditType("Location"));
    localStorage.setItem("activeEditType", "Location");
    navigate("/editLocation");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/company/location/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Location deleted successfully.");
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to delete Location.");
      });
  };

  useEffect(() => {
    setIsloading(true);
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/details/${activeCompany._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setShowEmployeeDirectory(res.data.employeeDirectory);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }, []);

  const columns = [
    {
      name: "S. No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Manager Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            {/* <img src={require("../../assets/img/eye.png")} /> */}
            <img
              style={{ cursor: "pointer" }}
              src={require("../../assets/img/edit_thin.png")}
              onClick={() => handleEdit(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../assets/img/delete.png")}
              // onClick={() => handleDelete(row._id)}
              onClick={() =>
                setLocationToDelete({ id: row._id, location: row.location })
              }
            />
          </div>
        </>
      ),
    },
  ];

  let showAddNew = true;
  let showCsvExport = false;
  let showDownload = false;
  let showExport = false;
  let showLocation = false;
  let showSms = true;
  let showFirstHr = false;
  let showSecondHr = true;
  let showActions = true;
  let showVisitor = true;

  if (userType === "Company Manager") {
    showAddNew = true;
    showCsvExport = false;
    showDownload = false;
    showExport = false;
    showLocation = false;
    showSms = false;
    showFirstHr = false;
    showSecondHr = false;
    showActions = false;
    showVisitor = false;
  }

  const options = {
    // title: activeCompany.name,
    title: "Location",
    showCsvExport: showCsvExport,
    showDownload: showDownload,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    type: "Location",
    columns: columns,
    apiUrl: `${base_url}/company/location/list`,
    companyId: activeCompany._id,
    showVisitor: showVisitor,
    employeeDirectorySwitch: employeeDirectory,
  };
  return (
    <>
      <Home propComponent={<Table options={options} isLoading={isLoading} />} />
      <ConfirmModal
        handleDelete={handleDelete}
        id={locationToDelete.id}
        msg={`Are you sure you want to delete ${locationToDelete.location} location?`}
      />
    </>
  );
};

export default LocationWrapper;
