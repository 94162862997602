import classes from "./VisitorDetails.module.css";
const moment = require("moment");
const VisitorDetails = ({ initialData }) => {
  const {
    address,
    companyId,
    companyName,
    createdAt,
    docFront,
    docBack,
    empDepartment,
    empDesignation,
    empName,
    empPhoneNumber,
    firstName,
    lastName,
    locationId,
    location,
    otpSent,
    actionSms,
    checkOutSms,
    notifyUserSms,
    notifyVisitorSms,
    phone,
    purposeOfVisit,
    selfie,
    updatedAt,
    _id,
    questionOne,
    questionTwo,
    questionThree,
    questionFour,
    questionFive,
    answerOne,
    answerTwo,
    answerThree,
    answerFour,
    answerFive,
  } = initialData;

  return (
    <>
      <div className={classes.UserDiv}>
        <h3>{firstName}</h3>
        <section className={` ${classes.Container}`}>
          <div className={classes.userImgContainer}>
            <div className={classes.imgDiv}>
              <div className={classes.idCont}>
                <div>
                  <img src={selfie} />
                </div>
                <p>Photo</p>
              </div>

              {docFront && (
                <div className={classes.idCont}>
                  <div>
                    <img src={docFront} />
                  </div>
                  <p>Valid ID (Front)</p>
                </div>
              )}

              {docBack && (
                <div className={classes.idCont}>
                  <div>
                    <img src={docBack} />
                  </div>
                  <p>Valid ID (Back)</p>
                </div>
              )}
            </div>

            <div className={classes.timeDiv}>
              <p>
                OTP Time : {otpSent && moment.utc(otpSent).format("h:mm A")}
              </p>
              <p>
                SMS Time :{" "}
                {notifyUserSms && moment.utc(notifyUserSms).format("h:mm A")}
              </p>
              {notifyVisitorSms && (
                <p>
                  User FYI SMS :{" "}
                  {notifyVisitorSms &&
                    moment.utc(notifyVisitorSms).format("h:mm A")}
                </p>
              )}
              {actionSms && (
                <p>
                  Approve/Disapprove Time :{" "}
                  {actionSms && moment.utc(actionSms).format("h:mm A")}
                </p>
              )}
              {checkOutSms && (
                <p>
                  Checkout Time :{" "}
                  {checkOutSms && moment.utc(checkOutSms).format("h:mm A")}
                </p>
              )}
            </div>
          </div>
          <hr className={classes.hr1} />
          <div className={classes.detailsCont}>
            <div className={classes.detailsDiv}>
              <p>First Name : {firstName}</p>
              <p>Last Name : {lastName}</p>
              <p>Company Name : {companyName}</p>
              <p>Address : {address}</p>
              <p>Company Location : {location}</p>
              <p>Meeting Person Name : {empName}</p>
              <p>Meeting Person Designation : {empDesignation}</p>
              <p>Meeting Person Department : {empDesignation}</p>
            </div>

            <div className={classes.vl}></div>

            {window.screen.width <= 480 ? <hr className={classes.hr1} /> : ""}
            <div className={classes.questionsDiv}>
              <div>
                {questionOne && (
                  <div>
                    <p>Q1. {questionOne}</p>
                    <div className={classes.answer}>
                      <p>- {answerOne}</p>
                    </div>
                  </div>
                )}
                {questionTwo && <hr className={classes.hr2} />}
                {questionTwo && (
                  <div>
                    <p>Q2. {questionTwo}</p>
                    <div className={classes.answer}>
                      <p>- {answerTwo}</p>
                    </div>
                  </div>
                )}
                {questionThree && <hr className={classes.hr2} />}
                {questionThree && (
                  <div>
                    <p>Q3. {questionThree}</p>
                    <div className={classes.answer}>
                      <p>- {answerThree}</p>
                    </div>
                  </div>
                )}
                {questionFour && <hr className={classes.hr2} />}
                {questionFour && (
                  <div>
                    <p>Q4. {questionFour}</p>
                    <div className={classes.answer}>
                      <p>- {answerFour}</p>
                    </div>
                  </div>
                )}
                {questionFive && <hr className={classes.hr2} />}
                {questionFive && (
                  <div>
                    <p>Q5. {questionFive}</p>
                    <div className={classes.answer}>
                      <p>- {answerFive}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default VisitorDetails;
