import { useEffect } from "react";
import classes from "../AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;

const Profile = ({ ProfileName, ProfilEmail, ProfilePhone, userId, token }) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z]+$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: ProfileName,
      email: ProfilEmail,
      phone: ProfilePhone,
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your name"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter your phone number"),
      new_password: Yup.string()
        .min(8, "password must be 8 characters long")
        .required("Please enter your new password"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Please enter your new password"),
    }),
    onSubmit: (values) => {
      let reqObj = {
        method: "PUT",
        url: `${base_url}/user/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          userId: userId,
          email: values.email,
          phone: values.phone,
          name: values.name,
          password: values.new_password,
        },
      };

      axios(reqObj)
        .then((res) => {
          toast.dismiss();
          toast.success("Profile Updated Successfully!!");
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(
            err.response.data.error.message || "Failed to update Profile."
          );
        });
    },
  });
  return (
    <>
      <div className={classes.mainCont}>
        <h3>My Profile</h3>

        <form className={classes.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={classes.formGrp}>
            <label for="name">Name:</label>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                {...formik.getFieldProps("name")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.name && formik.errors.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="email">Email:</label>
            <div>
              <input
                type="text"
                id="email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.email && formik.errors.email ? (
                  <div>{formik.errors.email}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="phone">Phone:</label>
            <div>
              <input
                type="text"
                id="phone"
                name="phone"
                {...formik.getFieldProps("phone")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.phone && formik.errors.phone ? (
                  <div>{formik.errors.phone}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="new_password">New Password:</label>
            <div>
              <input
                type="password"
                id="new_password"
                name="new_password"
                {...formik.getFieldProps("new_password")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.new_password && formik.errors.new_password ? (
                  <div>{formik.errors.new_password}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="confirm_password">Confirm Password:</label>
            <div>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                {...formik.getFieldProps("confirm_password")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.confirm_password &&
                formik.errors.confirm_password ? (
                  <div>{formik.errors.confirm_password}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formBtn}>
            <button className={classes.createBtn} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
