import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import classes from "../Login/Login.module.css";
import VisitorClasses from "../VisitorPass/VisitorPass.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");
const EndPass = () => {
  const location = useLocation();
  let Id = location.pathname.substring(9, location.pathname.length);
  const [didPass, setDigiPass] = useState();
  const [footerText1, setfooterText1] = useState("");
  const [footerText2, setfooterText2] = useState("");
  const [domLoaded, setDomLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${base_url}/visitor/detail/${Id}`).then((res) => {
      setDigiPass(res.data.data);
      // setDomLoaded(true);
      return axios(
        `${base_url}/visitor/company/details/${res.data.data.companyId}`
      ).then((res) => {
        if (res.data.data.footerStatus) {
          setfooterText1(res.data.data.footerTextOne);
          setfooterText2(res.data.data.footerTextTwo);
        }
        // setCompanyData(res.data.data);
        setDomLoaded(true);
      });
    });
  }, []);

  // {
  //   domLoaded && console.log("Date.......", didPass.createdAt);
  // }

  const handleEndMeeting = () => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/end-meeting`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        visitorId: Id,
      },
    };
    axios(reqObj)
      .then((res) => {
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log(err);
      });
    // /visitor/end-meeting
  };

  return (
    <>
      {domLoaded && (
        <div className="wrapper-box">
          <div className="container">
            <div className="app-panel">
              <div className="wrapper-mid">
                <div className="logo-div">
                  <img src={require("../../assets/img/images/logo.png")} />
                </div>

                <div className="text-logo">
                  <h1>Digi-Pass</h1>
                  <p>
                    Visitor Management <br></br>System
                  </p>
                </div>
                <div className="body-mid">
                  <div className="event-div">
                    <div className="pic-img">
                      <img src={didPass.selfie} />
                    </div>
                    <div className="content">
                      <p>
                        <strong>{`${didPass.firstName} ${didPass.lastName}`}</strong>
                      </p>
                      <p>{didPass.phone}</p>
                      <p>{didPass.location}</p>
                    </div>
                  </div>
                  <div className="event-div">
                    <div className="content">
                      <p className="govtid">Govt ID -</p>
                    </div>
                    {didPass.docFront && (
                      <div className="pic-img">
                        <img src={didPass.docFront} />
                        <p>Front</p>
                      </div>
                    )}
                    <div className="space-div"></div>
                    {didPass.docBack && (
                      <div className="pic-img">
                        <img src={didPass.docBack} />
                        <p>Back</p>
                      </div>
                    )}
                  </div>
                  <div className="event-div">
                    <p>
                      <label>Company Name</label>- {didPass.companyName}
                    </p>
                    <p>
                      <label>Meeting Person</label>- {didPass.empName}
                    </p>
                    <p>
                      <label>Pass Type</label>- Daily Pass
                    </p>
                    <p>
                      <label>Purpose of visit</label>- {didPass.purposeOfVisit}
                    </p>
                    <p>
                      <label>Valid Up to</label>-{" "}
                      {didPass.createdAt.substring(0, 10)}
                    </p>
                    <p>
                      <label>Created Date</label>-{" "}
                      {/* {`${moment
                        .utc(didPass.createdAt)
                        .local()
                        .format("YYYY-MM-DD")}, ${moment
                        .utc(didPass.createdAt)
                        .local()
                        .format("h:mm A")}`} */}
                      {moment.utc(didPass.otpSent).format("MMM D, hh:mm A")}
                      {/* {moment(didPass.notifyUserSms).format("MMM D, hh:mm A")} */}
                      {/* {moment(didPass.createdAt).format("MMM D, hh:mm A")} */}
                    </p>
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-danger reject-btn"
                      style={{ width: "100%" }}
                      onClick={handleEndMeeting}
                    >
                      End Meeting
                    </button>
                  </div>
                </div>

                <footer className="visitor-pass-footer">
                  <p>
                    {footerText1} <br></br> {footerText2}
                  </p>
                </footer>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EndPass;
