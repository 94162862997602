import logo from "./logo.svg";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import Login from "./SuperAdmin/components/loginPage/Login";
import Phone from "./SuperAdmin/components/phoneNo/Phone";
import OTPVerification from "./SuperAdmin/components/OtpVerification/OTPVerification";
import ChangePassword from "./SuperAdmin/components/ChangePassword/ChangePassword";
import Home from "./SuperAdmin/components/Home/Home";
import Table from "./SuperAdmin/components/Table/Table";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import DashboardWrapper from "./SuperAdmin/components/Dashboard/DashboardWrapper";
import CompaniesWrapper from "./SuperAdmin/components/CompaniesWrapper/CompaniesWrapper";
import ProfileWrapper from "./SuperAdmin/components/Profile/ProfileWrapper";
import TermsWrapper from "./SuperAdmin/components/Terms/TermsWrapper";
import SubadminWrapper from "./SuperAdmin/components/SubadminWrapper/SubadminWrapper";
import SingleCompanyWrapper from "./SuperAdmin/components/SingleCompanyWrapper/SingleCompanyWrapper";
import VisitorDetailsWrapper from "./SuperAdmin/components/VisitorDetails/VisitorDetailsWrapper";
import SmsConfigWrapper from "./SuperAdmin/components/SmsConfiguration/SmsConfigWrapper";
import AddNewCompanyWrapper from "./SuperAdmin/components/AddNewCompany/AddNewCompanyWrapper";
import AddNewBuildingWrapper from "./SuperAdmin/components/AddNewBuilding/AddNewBuildingWrapper";
import AddSubAdminWrapper from "./SuperAdmin/components/AddNewSubAdmin/AddSubAdminWrapper";
import EmployeeDirectoryWrapper from "./SuperAdmin/components/EmployeeDirectoryWrapper/EmployeeDirectoryWrapper";
import LocationWrapper from "./SuperAdmin/components/LocationWrapper/LocationWrapper";
import AddEmployeeDirectoryWrapper from "./SuperAdmin/components/AddEmployeeDirectory/AddEmployeeDirectoryWrapper";
import AddNewLocationWrapper from "./SuperAdmin/components/AddNewLocation/AddNewLocationWrapper";
//Visitor
import VisitorLogin from "./Visitor/components/Login/Login";
import VisitorOtpVerification from "./Visitor/components/OtpVerification/OtpVerification";

import CovidQuestions from "./Visitor/components/CovidQuestions/CovidQuestions";
import EndPass from "./Visitor/components/EndPass/EndPass";
import GovtId from "./Visitor/components/GovtId/GovtId";
import Selfie from "./Visitor/components/Selfie/Selfie";
import Terms from "./Visitor/components/Terms/Terms";
import UserForm from "./Visitor/components/UserForm/UserForm";
import VisitorPass from "./Visitor/components/VisitorPass/VisitorPass";
// import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import CameraComponent from "./Visitor/components/Camera/Camera";
import { useContext } from "react";
import authContext from "./AuthContext/authContext";
import PrivateRoutes from "./SuperAdmin/components/PrivateRoutes/PrivateRoutes";
import EditNewCompanyWrapper from "./SuperAdmin/components/EditNewCompany/EditNewCompanyWrapper";
import EditEmployeeWrapper from "./SuperAdmin/components/EditEmployee/EditEmployeeWrapper";
import EditSubadminWrapper from "./SuperAdmin/components/EditSubadmin/EditSubadminWrapper";
import EditLocationWrapper from "./SuperAdmin/components/EditLocation/EditLocationWrapper";
import VisitorWrapper from "./SuperAdmin/components/VisitorWrapper/VisitorWrapper";
import ToastMsg from "./SuperAdmin/components/ToastMsg/ToastMsg";
import EditNewBuildingWrapper from "./SuperAdmin/components/EditNewBuilding/EditNewBuildingWrapper";
import Thankyou from "./Visitor/components/Thankyou/Thankyou";
import { useEffect } from "react";
import BuildingWrapper from "./SuperAdmin/components/BuildingWrapper/BuildingWrapper";
import AddCompanyInBuilding from "./SuperAdmin/components/AddCompanyInBuilding/AddCompanyInBuilding";
import AddCompanyInBuildingWrapper from "./SuperAdmin/components/AddCompanyInBuilding/AddCompanyInBuildingWrapper";
import AddEmployeeInCompany from "./SuperAdmin/components/AddEmployeeInCompany/AddEmployeeInCompany";
import AddEmployeeInCompanyWrapper from "./SuperAdmin/components/AddEmployeeInCompany/AddEmployeeInCompanyWrapper";
import CompanyEmployeeDirectory from "./SuperAdmin/components/building/CompanyEmployeeDirectory/CompanyEmployeeDirectory";
function App() {
  // const navigate = useNavigate();
  const ctx = useContext(authContext);
  const isLoggedIn = ctx.isLoggedIn;

  useEffect(() => {
    // Set the --vh custom property to the height of the viewport
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    setVh();
    // Update the --vh custom property on window resize
    window.addEventListener("resize", setVh);
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  return (
    // <div style={{ height: "100%" }}>
    <div className="AppContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/phone" element={<Phone />} />
          <Route path="/otp-verification" element={<OTPVerification />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<DashboardWrapper />} />
            <Route path="/companies" element={<CompaniesWrapper />} />
            <Route
              path="/companies/company"
              element={<SingleCompanyWrapper />}
            />
            <Route
              path="/companies/company/visitor-details"
              element={<VisitorDetailsWrapper />}
            />
            <Route
              path="/employeeDirectory"
              element={<EmployeeDirectoryWrapper />}
            />
            <Route
              path="/CompanyEmployeeDirectory"
              element={<CompanyEmployeeDirectory />}
            />
            <Route path="/location" element={<LocationWrapper />} />
            <Route path="/building" element={<BuildingWrapper />} />
            <Route path="/visitors-list" element={<VisitorWrapper />} />
            <Route
              path="/addEmployeeDirectory"
              element={<AddEmployeeDirectoryWrapper />}
            />
            <Route
              path="/addEmployeeInCompany"
              element={<AddEmployeeInCompanyWrapper />}
            />
            <Route path="/editEmployee" element={<EditEmployeeWrapper />} />
            <Route path="/addCompany" element={<AddNewCompanyWrapper />} />
            <Route path="/editCompany" element={<EditNewCompanyWrapper />} />
            <Route path="/editBuilding" element={<EditNewBuildingWrapper />} />
            <Route path="/addLocation" element={<AddNewLocationWrapper />} />
            <Route
              path="/addCompanyInBuilding"
              element={<AddCompanyInBuildingWrapper />}
            />
            <Route path="/editLocation" element={<EditLocationWrapper />} />
            <Route path="/addBuilding" element={<AddNewBuildingWrapper />} />
            <Route path="/addSubadmin" element={<AddSubAdminWrapper />} />
            <Route path="/editSubadmin" element={<EditSubadminWrapper />} />
            <Route path="/smsSetting" element={<SmsConfigWrapper />} />
            <Route path="/sub-admin" element={<SubadminWrapper />} />
            <Route path="/profile" element={<ProfileWrapper />} />
            <Route path="/terms" element={<TermsWrapper />} />
          </Route>

          {/* Visitor App */}

          <Route path="/visitor/:id" element={<VisitorLogin />} />

          <Route
            path="/visitor-otp-verification"
            element={<VisitorOtpVerification />}
          />

          <Route path="/visitor-details" element={<UserForm />} />
          <Route path="/selfie" element={<Selfie />} />
          <Route path="/camera" element={<CameraComponent />} />
          <Route path="/govt-id" element={<GovtId />} />
          <Route path="/covid-questions" element={<CovidQuestions />} />
          <Route path="/digipass/:id" element={<VisitorPass />} />
          <Route path="/user/:id" element={<VisitorPass />} />
          <Route path="/meeting/:id" element={<EndPass />} />
          <Route path="/visitor-terms" element={<Terms />} />
          <Route path="/thank-you" element={<Thankyou />} />
        </Routes>
        <ToastMsg />
      </BrowserRouter>
      {/* <Login /> */}
      {/* <Phone /> */}
      {/* <OTPVerification /> */}
      {/* <ChangePassword /> */}
      {/* <Home /> */}
      {/* <Table /> */}
      {/* </div> */}
    </div>
  );
}

export default App;
