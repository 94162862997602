import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import { useSelector } from "react-redux";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function LineChart() {
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const [lineData, setLineData] = useState([]);
  const payload = useSelector((state) => state.appData.barChartPayload);
  const lineChartFreqency = useSelector(
    (state) => state.appData.barChartFreqency
  );

  useEffect(() => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/dashboard/visitors`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
      data: { ...payload, frequency: lineChartFreqency },
    };

    axios(reqObj)
      .then((res) => {
        setLineData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [payload, lineChartFreqency]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = lineData.map((item) => {
    return item["_id"];
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: lineData.map((item) => {
          return item["count"];
        }),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}
