import Home from "../Home/Home";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../AuthContext/authContext";
import EditNewBuilding from "./EditNewBuilding";
import Loader from "../Loader/Loader";
const base_url = process.env.REACT_APP_BASE_URL;

const EditNewBuildingWrapper = () => {
  const activeEditCompanyId = useSelector(
    (state) => state.appData.activeEditCompanyId
  );
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/details/${activeEditCompanyId}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data });
        setDomLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Home
        propComponent={
          domLoaded ? (
            <EditNewBuilding initialData={initialData} />
          ) : (
            <>
              <Loader centerLoader={"centerLoader"} />
            </>
          )
        }
      />
    </>
  );
};

export default EditNewBuildingWrapper;
