import { Link, useNavigate } from "react-router-dom";
import classes from "./Login.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useContext } from "react";
import jwt_decode from "jwt-decode";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import { useDispatch } from "react-redux";
const base_url = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const ctx = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
      password: Yup.string()
        .min(8, "password must be 8 characters long")
        .required("Please enter a password"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let reqObj = {
        method: "POST",
        url: `${base_url}/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: values.email,
          password: values.password,
        },
      };

      axios(reqObj)
        .then((res) => {
          const userData = res.data;
          let token = res.data.token;
          let decoded = jwt_decode(token);
          ctx.login(res.data.token, decoded.exp * 1000);
          if (
            res.data.user_type === "Company Manager" ||
            res.data.user_type === "location" ||
            res.data.user_type === "Building Manager" ||
            res.data.user_type === "company"
          ) {
            ctx.setUserDetailedData(res.data.userData);
            localStorage.setItem(
              "userDetailedData",
              JSON.stringify(res.data.userData)
            );

            if (
              res.data.user_type === "location" ||
              res.data.user_type === "Building Manager" || //location is company here
              res.data.user_type === "company"
            ) {
              dispatch(
                appActions.setActiveLocation({
                  _id: res.data.userData._id,
                  location: res.data.userData.location,
                })
              );

              localStorage.setItem(
                "activeLocation",
                JSON.stringify({
                  _id: res.data.userData._id,
                  location: res.data.userData.location,
                })
              );
            }
            if (res.data.user_type === "company") {
              dispatch(
                appActions.setActiveBuilding({
                  _id: res.data.userData._id,
                  location: res.data.userData.location,
                })
              );

              localStorage.setItem(
                "activeBuilding",
                JSON.stringify({
                  _id: res.data.userData._id,
                  location: res.data.userData.location,
                })
              );
            }

            if (res.data.user_type === "Building Manager") {
              dispatch(
                appActions.setActiveBuilding({
                  _id: res.data.userData.company_id,
                })
              );

              localStorage.setItem(
                "activeBuilding",
                JSON.stringify({
                  _id: res.data.userData.company_id,
                })
              );
            }
            // activeBuilding
          }
          navigate("/dashboard");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          formik.setFieldError("password", "Username or password is incorrect");
          setIsLoading(false);
        });
    },
  });

  return (
    <div className={classes.loginConntainer}>
      <div className={classes.loginLeft}>
        <div className={classes.loginBg1}>
          <img src={require("../../assets/img/bgLogin_1.png")} />
        </div>

        <div className={classes.centerCont}>
          <img src={require("../../assets/img/Digipass_logo.png")} />
          <h1>Digi-Pass</h1>
          <p>Visitor Management</p>
          <p>System</p>
        </div>

        <div className={classes.loginBg2}>
          <img src={require("../../assets/img/bgLogin_2.png")} />
        </div>
      </div>

      <div className={classes.loginRight}>
        <div className={classes.loginBgR1}>
          <img src={require("../../assets/img/bgLoginR1.png")} />
        </div>

        {/* Form */}
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.centerContR}>
            <h2>Login to your account</h2>

            <div className={classes.inputDiv}>
              <div className={classes.inputFieldLogo}>
                <img src={require("../../assets/img/mailIcon.png")} />
              </div>
              <div className={classes.inputField}>
                <input
                  id="email"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  type="email"

                  // onChange={() => setReqErr("")}
                />
              </div>
            </div>
            <span className={classes.errMsg}>
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </span>

            <div className={classes.inputDiv}>
              <div className={classes.inputFieldLogo}>
                <img src={require("../../assets/img/lockIcon.png")} />
              </div>
              <div className={classes.inputField}>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  {...formik.getFieldProps("password")}

                  // onChange={() => setReqErr("")}
                />
              </div>
            </div>

            <span className={classes.errMsg}>
              {formik.touched.password && formik.errors.password ? (
                <div>{formik.errors.password}</div>
              ) : null}
            </span>

            <p className={classes.ForgotPass}>
              <Link to="/phone">Forget Password</Link>{" "}
            </p>

            <button
              type="submit"
              className={classes.loginBtn}
              // onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </form>

        <div className={classes.loginBgR2}>
          <div className={classes.div1}>
            <p>
              Powered by Fedicoms Pvt Ltd. <br />
              www.fedicoms.com
            </p>
          </div>
          {/* <div className={classes.div2}>
            <img src={require("../../assets/img/bgLoginR2.png")} />
          </div> */}
        </div>

        <div className={classes.div2}>
          <img src={require("../../assets/img/bgLoginR2.png")} />
        </div>
      </div>
    </div>
  );
};

export default Login;
