import classes from "./Home.module.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { useState } from "react";
import Table from "../Table/Table";
import UserDetails from "../VisitorDetails/VisitorDetails";
import AddEmployeeDirectory from "../AddEmployeeDirectory/AddEmployeeDirectory";
import AddNewLocation from "../AddNewLocation/AddNewLocation";
import Profile from "../Profile/Profile";
import AddNewSubAdmin from "../AddNewSubAdmin/AddNewSubAdmin";
import AddNewCompany from "../AddNewCompany/AddNewCompany";
import EditNewCompany from "../EditNewCompany/EditNewCompany";
import Terms from "../Terms/Terms";
import AddNewBuilding from "../AddNewBuilding/AddNewBuilding";
import EditNewBuilding from "../EditNewBuilding/EditNewBuilding";
import SmsConfiguration from "../SmsConfiguration/SmsConfiguration";
import { BarChart } from "../Chart/BarChart";
import Dashboard from "../Dashboard/Dashboard";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../../ReduxStore/appDataSlice";
import { useEffect } from "react";
import axios from "axios";
import { useMemo } from "react";
import LoadingOverlay from "react-loading-overlay";
import "../../../Visitor/assets/css/master.css";
const base_url = process.env.REACT_APP_BASE_URL;
const Home = ({ propComponent, isLoading }) => {
  const ctx = useContext(authContext);
  console.log(ctx);
  const userType = ctx.userData.user_type;
  const [showEmployeeDirectory, setShowEmployeeDirectory] = useState("");
  let showCompany = userType === "admin" || userType === "subadmin";
  let showSubadmin = userType === "admin";
  let showLocation = userType === "Company Manager";
  let showReports =
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager" ||
    userType === "Building Manager" ||
    userType === "company";

  let showBuildingCompany = userType === "Building Manager";
  let showCompanyEmployeeDirectory = userType === "company";
  const path = window.location.pathname;
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      userType === "Company Manager" ||
      userType === "location" ||
      userType === "company" ||
      userType === "Building Manager"
    ) {
      dispatch(
        appActions.setActiveCompany({ _id: ctx.userDetailedData.company_id })
      );
      localStorage.setItem(
        "activeCompany",
        JSON.stringify({ _id: ctx.userDetailedData.company_id })
      );

      if (userType === "Building Manager") {
        return;
      }

      if (userType === "company") {
        dispatch(
          appActions.setActiveBuilding({ _id: ctx.userDetailedData.company_id })
        );

        localStorage.setItem(
          "activeBuilding",
          JSON.stringify({ _id: ctx.userDetailedData.company_id })
        );
      }

      // axios.get("http://localhost:3000/company/details/63ec771ba21f18458f843e65")
      let reqObj = {
        method: "GET",
        url: `${base_url}/company/details/${ctx.userDetailedData.company_id}`,
        headers: {
          "Content-Type": "application/javascript",
          Authorization: `Bearer ${ctx.token}`,
        },
      };

      axios(reqObj)
        .then((res) => {
          if (userType !== "company") {
            setShowEmployeeDirectory(res.data.employeeDirectory);
            dispatch(
              appActions.setShowEmployeeDirectory(res.data.employeeDirectory)
            );
            localStorage.setItem(
              "showEmployeeDirectory",
              res.data.employeeDirectory
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  let title = "";

  if (
    !(
      path.includes("dashboard") ||
      path.includes("companies") ||
      path.includes("sub-admin") ||
      path.includes("profile") ||
      path.includes("addSubadmin") ||
      path.includes("editSubadmin") ||
      path.includes("editCompany") ||
      path.includes("editBuilding") ||
      path.includes("addCompany") ||
      path.includes("addCompany") ||
      path.includes("addBuilding")
    )
  ) {
    title = activeCompany ? activeCompany["name"] : "";
  }

  return (
    <>
      <div
        className="wrapper-box"
        style={{ backgroundColor: "transparent", maxWidth: "100%" }}
      >
        <div
          className="container"
          style={{
            maxWidth: "100%",
            margin: "0",
          }}
        >
          <div className={classes.containerAll}>
            <Header />

            <section className={classes.mainContainer} style={{ zIndex: "10" }}>
              {/* <nav className={classes.navbar}> */}
              <Sidebar
                backgroundColor="#003CA6"
                defaultCollapsed={window.screen.width > 1100 ? false : true}
              >
                <div className={classes.navTop}>
                  <div>
                    <img src={require("../../assets/img/Digipass_logo.png")} />
                  </div>
                  <h1>Digi-Pass</h1>
                  <p>Visitor Management</p>
                  <p>System</p>
                </div>

                <hr className={classes.sideHr} />
                <Menu>
                  <MenuItem
                    className={classes.setBorder}
                    onClick={() => navigate("/dashboard")}
                    active={path === "/dashboard"}
                    rootStyles={{
                      backgroundColor: path.includes("/dashboard")
                        ? "#F9F9F9"
                        : "transparent",
                    }}
                  >
                    <div
                      className={classes.navItem}
                      style={{
                        color: path.includes("/dashboard")
                          ? "#003CA6"
                          : "#ffffff",
                      }}
                    >
                      {path.includes("/dashboard") && (
                        <img
                          src={require("../../assets/img/dashboard_blue.png")}
                        />
                      )}
                      {!path.includes("/dashboard") && (
                        <img
                          src={require("../../assets/img/dashboard_logo.png")}
                        />
                      )}
                      {/* <p>Dashboard</p> */}
                      Dashboard
                    </div>
                  </MenuItem>

                  {showCompany && (
                    <MenuItem
                      className={classes.setBorder}
                      active={
                        path.includes("/companies") ||
                        path.includes("/location") ||
                        path.includes("/building") ||
                        path.includes("/CompanyEmployeeDirectory") ||
                        path.includes("/visitors-list") ||
                        path.includes("/employeeDirectory") ||
                        path.includes("/terms") ||
                        path.includes("/smsSetting") ||
                        path.includes("/addEmployeeDirectory") ||
                        path.includes("/editEmployee")
                      }
                      rootStyles={{
                        backgroundColor:
                          path.includes("/companies") ||
                          path.includes("/location") ||
                          path.includes("/building") ||
                          path.includes("/CompanyEmployeeDirectory") ||
                          path.includes("/visitors-list") ||
                          path.includes("/employeeDirectory") ||
                          path.includes("/terms") ||
                          path.includes("/smsSetting") ||
                          path.includes("/addEmployeeDirectory") ||
                          path.includes("/editEmployee")
                            ? "#F9F9F9"
                            : "transparent",
                      }}
                      onClick={() => navigate("/companies")}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color:
                            path.includes("/companies") ||
                            path.includes("/location") ||
                            path.includes("/building") ||
                            path.includes("/CompanyEmployeeDirectory") ||
                            path.includes("/visitors-list") ||
                            path.includes("/employeeDirectory") ||
                            path.includes("/terms") ||
                            path.includes("/smsSetting") ||
                            path.includes("/addEmployeeDirectory") ||
                            path.includes("/editEmployee")
                              ? "#003CA6"
                              : "#ffffff",
                        }}
                      >
                        {(path.includes("/companies") ||
                          path.includes("/location") ||
                          path.includes("/building") ||
                          path.includes("/CompanyEmployeeDirectory") ||
                          path.includes("/visitors-list") ||
                          path.includes("/employeeDirectory") ||
                          path.includes("/terms") ||
                          path.includes("/smsSetting") ||
                          path.includes("/addEmployeeDirectory") ||
                          path.includes("/editEmployee")) && (
                          <img
                            src={require("../../assets/img/company_logo.png")}
                          />
                        )}
                        {!(
                          path.includes("/companies") ||
                          path.includes("/location") ||
                          path.includes("/building") ||
                          path.includes("/CompanyEmployeeDirectory") ||
                          path.includes("/visitors-list") ||
                          path.includes("/employeeDirectory") ||
                          path.includes("/terms") ||
                          path.includes("/smsSetting") ||
                          path.includes("/addEmployeeDirectory") ||
                          path.includes("/editEmployee")
                        ) && (
                          <img
                            src={require("../../assets/img/company_white.png")}
                          />
                        )}
                        {/* <p>Companies</p> */}
                        Companies
                      </div>
                    </MenuItem>
                  )}

                  {/* Building Manager Company */}

                  {showBuildingCompany && (
                    <MenuItem
                      className={classes.setBorder}
                      active={
                        path.includes("/building") ||
                        path.includes("/location") ||
                        path.includes("/employeeDirectory")
                      }
                      rootStyles={{
                        backgroundColor:
                          path.includes("/building") ||
                          path.includes("/location") ||
                          path.includes("/employeeDirectory")
                            ? "#F9F9F9"
                            : "transparent",
                      }}
                      onClick={() => navigate("/building")}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color:
                            path.includes("/building") ||
                            path.includes("/location") ||
                            path.includes("/employeeDirectory")
                              ? "#003CA6"
                              : "#ffffff",
                        }}
                      >
                        {(path.includes("/building") ||
                          path.includes("/location") ||
                          path.includes("/employeeDirectory")) && (
                          <img
                            src={require("../../assets/img/company_logo.png")}
                          />
                        )}
                        {!(
                          path.includes("/building") ||
                          path.includes("/location") ||
                          path.includes("/employeeDirectory")
                        ) && (
                          <img
                            src={require("../../assets/img/company_white.png")}
                          />
                        )}
                        {/* <p>Companies</p> */}
                        Company
                      </div>
                    </MenuItem>
                  )}

                  {showLocation && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: path.includes("/location")
                          ? "#F9F9F9"
                          : "transparent",
                      }}
                      onClick={() => {
                        navigate("/location");
                      }}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: path.includes("/location")
                            ? "#003CA6"
                            : "#ffffff",
                        }}
                      >
                        {path.includes("/location") && (
                          <img
                            src={require("../../assets/img/company_logo.png")}
                          />
                        )}
                        {!path.includes("/location") && (
                          <img
                            src={require("../../assets/img/company_white.png")}
                          />
                        )}
                        {/* <p>My Profile</p> */}
                        Location
                      </div>
                    </MenuItem>
                  )}

                  {showReports && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: path.includes("/visitors-list")
                          ? "#F9F9F9"
                          : "transparent",
                      }}
                      onClick={() => {
                        navigate("/visitors-list");
                      }}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: path.includes("/visitors-list")
                            ? "#003CA6"
                            : "#ffffff",
                        }}
                      >
                        {path.includes("/visitors-list") && (
                          <img
                            src={require("../../assets/img/reports_blue.png")}
                          />
                        )}
                        {!path.includes("/visitors-list") && (
                          <img src={require("../../assets/img/reports.png")} />
                        )}
                        {/* <p>My Profile</p> */}
                        Reports
                      </div>
                    </MenuItem>
                  )}

                  {showEmployeeDirectory && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: path.includes("/employeeDirectory")
                          ? "#F9F9F9"
                          : "transparent",
                      }}
                      onClick={() => {
                        navigate("/employeeDirectory");
                        if (userType !== "location") {
                          dispatch(
                            appActions.setActiveLocation({
                              _id: "",
                              location: "All",
                            })
                          );
                          localStorage.setItem(
                            "activeLocation",
                            JSON.stringify({ _id: "", location: "All" })
                          );
                        }
                      }}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: path.includes("/employeeDirectory")
                            ? "#003CA6"
                            : "#ffffff",
                        }}
                      >
                        {path.includes("/employeeDirectory") && (
                          <img src={require("../../assets/img/copy.png")} />
                        )}
                        {!path.includes("/employeeDirectory") && (
                          <img
                            src={require("../../assets/img/copy_white.png")}
                          />
                        )}
                        {/* <p>My Profile</p> */}
                        Employee Directory
                      </div>
                    </MenuItem>
                  )}

                  {/* Building Company Employee Listing */}
                  {showCompanyEmployeeDirectory && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: path.includes(
                          "/CompanyEmployeeDirectory"
                        )
                          ? "#F9F9F9"
                          : "transparent",
                      }}
                      onClick={() => {
                        navigate("/CompanyEmployeeDirectory");
                        // if (userType !== "location") {
                        //   dispatch(
                        //     appActions.setActiveLocation({
                        //       _id: "",
                        //       location: "All",
                        //     })
                        //   );
                        //   localStorage.setItem(
                        //     "activeLocation",
                        //     JSON.stringify({ _id: "", location: "All" })
                        //   );
                        // }
                      }}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: path.includes("/CompanyEmployeeDirectory")
                            ? "#003CA6"
                            : "#ffffff",
                        }}
                      >
                        {path.includes("/CompanyEmployeeDirectory") && (
                          <img src={require("../../assets/img/copy.png")} />
                        )}
                        {!path.includes("/CompanyEmployeeDirectory") && (
                          <img
                            src={require("../../assets/img/copy_white.png")}
                          />
                        )}
                        Employee Directory
                      </div>
                    </MenuItem>
                  )}

                  {showSubadmin && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: path.includes("/sub-admin")
                          ? "#F9F9F9"
                          : "transparent",
                      }}
                      onClick={() => navigate("/sub-admin")}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: path.includes("/sub-admin")
                            ? "#003CA6"
                            : "#ffffff",
                        }}
                      >
                        {path.includes("/sub-admin") && (
                          <img
                            src={require("../../assets/img/subadmin_blue.png")}
                          />
                        )}
                        {!path.includes("/sub-admin") && (
                          <img
                            src={require("../../assets/img/subadmin_logo.png")}
                          />
                        )}
                        {/* <p>Sub Admin</p> */}
                        Sub Admin
                      </div>
                    </MenuItem>
                  )}

                  <MenuItem
                    className={classes.setBorder}
                    rootStyles={{
                      backgroundColor: path.includes("/profile")
                        ? "#F9F9F9"
                        : "transparent",
                    }}
                    onClick={() => navigate("/profile")}
                  >
                    <div
                      className={classes.navItem}
                      style={{
                        color: path.includes("/profile")
                          ? "#003CA6"
                          : "#ffffff",
                      }}
                    >
                      {path.includes("/profile") && (
                        <img src={require("../../assets/img/gear_blue.png")} />
                      )}
                      {!path.includes("/profile") && (
                        <img src={require("../../assets/img/gear.png")} />
                      )}
                      {/* <p>My Profile</p> */}
                      My Profile
                    </div>
                  </MenuItem>

                  {window.screen.width <= 1100 && (
                    <MenuItem
                      className={classes.setBorder}
                      rootStyles={{
                        backgroundColor: "transparent",
                      }}
                      onClick={() => ctx.logout()}
                    >
                      <div
                        className={classes.navItem}
                        style={{
                          color: "#ffffff",
                        }}
                      >
                        <img src={require("../../assets/img/logout.png")} />
                        Logout
                      </div>
                    </MenuItem>
                  )}

                  {/* logout */}
                  {/* <MenuItem
                className={classes.setBorder}
                rootStyles={{
                  backgroundColor: path.includes("/terms")
                    ? "#F9F9F9"
                    : "transparent",
                }}
                onClick={() => navigate("/terms")}
              >
                <div
                  className={classes.navItem}
                  style={{
                    color: path.includes("/terms") ? "#003CA6" : "#ffffff",
                  }}
                >
                  {path.includes("/terms") && (
                    <img src={require("../../assets/img/terms_blue.png")} />
                  )}
                  {!path.includes("/terms") && (
                    <img src={require("../../assets/img/terms_logo.png")} />
                  )}
                  
                  Terms & Conditions
                </div>
              </MenuItem> */}
                </Menu>
              </Sidebar>
              {/* </nav> */}
              <section className={classes.container}>
                <div className={classes.contentHead}>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      activeCompany.type === "Company"
                        ? navigate("/location")
                        : navigate("/building");
                    }}
                  >
                    {/* {path.includes("location") ||
                path.includes("employeeDirectory") ||
                path.includes("visitors-list") ||
                path.includes("terms") ||
                path.includes("smsSetting")
                  ? activeCompany.name
                  : ""} */}
                    {title}
                  </p>
                </div>

                <div className={classes.tableContainer}>{propComponent}</div>
                <Footer />
              </section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
