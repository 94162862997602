import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import classes from "../Login/Login.module.css";
import VisitorClasses from "./VisitorPass.module.css";
import "../../assets/css/master.css";
import moment from "moment";

const base_url = process.env.REACT_APP_BASE_URL;
const VisitorPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let Id;
  // let footerText1 = "";
  // let footerText2 = "";
  if (location.pathname.includes("digipass")) {
    Id = location.pathname.substring(10, location.pathname.length);
  } else {
    Id = location.pathname.substring(6, location.pathname.length);
  }

  const [didPass, setDigiPass] = useState();
  const [footerText1, setfooterText1] = useState("");
  const [footerText2, setfooterText2] = useState("");
  const [domLoaded, setDomLoaded] = useState(false);
  const path = location.pathname;
  let showApprove = !path.includes("digipass");

  useEffect(() => {
    axios.get(`${base_url}/visitor/detail/${Id}`).then((res) => {
      setDigiPass(res.data.data);
      console.log("Digipass Data", res.data.data);
      return axios(
        `${base_url}/visitor/company/details/${res.data.data.companyId}`
      ).then((res) => {
        if (res.data.data.footerStatus) {
          setfooterText1(res.data.data.footerTextOne);
          setfooterText2(res.data.data.footerTextTwo);
        }
        // setCompanyData(res.data.data);
        setDomLoaded(true);
      });
    });
  }, []);

  const handleApproveReject = (status) => {
    let reqObj = {
      method: "POST",
      url: `${base_url}/visitor/emp-decision`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        visitorId: Id,
        status: status,
      },
    };
    axios(reqObj)
      .then((res) => {
        navigate("/thank-you");
      })
      .catch((err) => {
        console.log(err);
      });
    // /visitor/end-meeting
  };

  return (
    <>
      {domLoaded && (
        <div className="wrapper-box">
          <div className="container">
            <div className="app-panel">
              <div className="wrapper-mid">
                <div className="logo-div">
                  <img src={require("../../assets/img/images/logo.png")} />
                </div>

                <div className="text-logo">
                  <h1>Digi-Pass</h1>
                  <p>
                    Visitor Management <br></br>System
                  </p>
                </div>
                <div className="body-mid">
                  <div className="event-div">
                    <div className="pic-img">
                      <img src={didPass.selfie} />
                    </div>
                    <div className="content">
                      <p>
                        <strong>{`${didPass.firstName} ${didPass.lastName}`}</strong>
                      </p>
                      <p>{didPass.phone}</p>
                      <p>{didPass.location}</p>
                    </div>
                  </div>
                  <div className="event-div">
                    <div className="content">
                      <p className="govtid">Govt ID -</p>
                    </div>
                    {didPass.docFront && (
                      <div className="pic-img">
                        <img src={didPass.docFront} />
                        <p>Front</p>
                      </div>
                    )}
                    <div className="space-div"></div>
                    {didPass.docBack && (
                      <div className="pic-img">
                        <img src={didPass.docBack} />
                        <p>Back</p>
                      </div>
                    )}
                  </div>
                  <div className="event-div">
                    <p>
                      <label>Company Name</label>- {didPass.companyName}
                    </p>
                    <p>
                      <label>Meeting Person</label>- {didPass.empName}
                    </p>
                    <p>
                      <label>Pass Type</label>- Daily Pass
                    </p>
                    <p>
                      <label>Purpose of visit</label>- {didPass.purposeOfVisit}
                    </p>
                    <p>
                      <label>Valid Up to</label>-{" "}
                      {didPass.createdAt.substring(0, 10)}
                    </p>
                    <p>
                      <label>Created Date</label>-{" "}
                      {moment.utc(didPass.otpSent).format("MMM D, hh:mm A")}
                      {/* {moment(didPass.notifyUserSms).format("MMM D, hh:mm A")} */}
                      {/* {moment(didPass.createdAt).format("MMM D, hh:mm A")} */}
                    </p>
                  </div>
                  {showApprove && (
                    <div className="form-group">
                      <button
                        className="btn btn-danger reject-btn"
                        onClick={() => handleApproveReject("rejected")}
                      >
                        Reject
                      </button>
                      <button
                        className="btn btn-success success-btn"
                        onClick={() => handleApproveReject("approved")}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                </div>
                <footer className="visitor-pass-footer">
                  <p>
                    {footerText1} <br></br> {footerText2}
                  </p>
                </footer>
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VisitorPass;
