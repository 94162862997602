import axios from "axios";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Home from "../Home/Home";
import Table from "../Table/Table";
import classes from "../Table/Table.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const CompaniesWrapper = () => {
  const ctx = useContext(authContext);
  const token = ctx.token;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyToDelete, setcompanyToDelete] = useState({});
  const [isLoading, setIsloading] = useState(false);

  const handleDownload = (id) => {
    setIsloading(true);
    console.log(id);
    // https://api.digipass.co.in/api/company/generate-backup

    let reqObj = {
      method: "GET",
      url: `${base_url}/company/generate-backup/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        // console.log("Download data", res.data.backupUrl);
        window.open(res["data"]["backupUrl"], "_blank");
        setIsloading(false);
      })
      .catch((err) => {
        toast.error("Failed to download report.");
        setIsloading(false);
      });
  };

  const handleEdit = (row) => {
    dispatch(appActions.setActiveEditCompanyId(row._id));
    localStorage.setItem("activeEditCompanyId", row._id);
    if (row.type == "Company") {
      navigate("/editCompany");
    } else {
      navigate("/editBuilding");
    }
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/company/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Company deleted successfully.");
        if (res["data"]["backupUrl"]) {
          window.open(res["data"]["backupUrl"], "_blank");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to delete company.");
      });
  };

  const columns = [
    {
      name: "Company ID",
      cell: (row, index) => row._id,
      // cell: (row, index) => index + 1,
    },
    {
      name: "Company Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Company Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Company Manager Name",
      selector: (row) => row.managerName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.managerEmail,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.managerPhone,
      sortable: true,
    },
    {
      name: "Allowed Location",
      selector: (row) => {
        if (row.type === "Building") {
          return "-";
        }
        return row.noOfLocation;
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            <img
              src={require("../../assets/img/downloads-icon.png")}
              onClick={() => handleDownload(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              src={require("../../assets/img/edit_thin.png")}
              onClick={() => handleEdit(row)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../assets/img/delete.png")}
              // onClick={() => handleDelete(row._id)}
              onClick={() =>
                setcompanyToDelete({ id: row._id, name: row.name })
              }
            />
          </div>
        </>
      ),
    },
  ];
  const options = {
    title: "Companies",
    showCsvExport: false,
    showDownload: false,
    showExport: false,
    showLocation: false,
    showSms: false,
    showAddNew: true,
    showFirstHr: false,
    showSecondHr: false,
    showActions: false,
    type: "Companies",
    columns: columns,
    apiUrl: `${base_url}/company/list`,
  };
  return (
    <>
      <Home propComponent={<Table options={options} isLoading={isLoading} />} />
      <ConfirmModal
        handleDelete={handleDelete}
        id={companyToDelete.id}
        msg={`Are you sure you want to delete ${companyToDelete.name}?`}
      />
    </>
  );
};

export default CompaniesWrapper;
