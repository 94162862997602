import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./UserForm.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import "../../assets/css/master.css";
import { toast } from "react-toastify";
import Departments from "../../../SuperAdmin/components/departmentDropDown/Departments";

const base_url = process.env.REACT_APP_BASE_URL;

const UserForm = () => {
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.visitorData.companyData);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");

  useEffect(() => {
    try {
      axios(`${base_url}/visitor/company/location/${companyData._id}`).then(
        (res) => {
          setLocations(res.data.location);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = /^[0-9]{10}$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const departmentRegExp = /^[A-Za-z-& ]+$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "firstName"
        ] || "",
      lastName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "lastName"
        ] || "",
      companyName:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "companyName"
        ] || "",
      Address:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }["Address"] ||
        "",
      Purpose:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }["Purpose"] ||
        "",
      meeting_person_phone:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_phone"
        ] || "",
      meeting_person_name:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_name"
        ] || "",
      meeting_person_designation:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_designation"
        ] || "",
      meeting_person_department:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "meeting_person_department"
        ] || "",
      locationId: "",
      companyOrBuildingLocations:
        { ...JSON.parse(localStorage.getItem("visitorFormData")) }[
          "companyOrBuildingLocations"
        ] || `${companyData.noOfLocation !== 1 ? "" : "Dummy"}`,
      // lat: lat,
      // long: lon,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your first name"),
      lastName: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter your last name"),
      companyName: Yup.string().required("Please enter your company name"),
      Address: Yup.string().required("Please enter your address"),
      Purpose: Yup.string().required("Please enter purpose of visit"),
      meeting_person_phone: Yup.string()
        .length(10, "Phone number should be of 10 digits")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter meeting person phone number"),
      meeting_person_name: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Meeting person name is required"),
      meeting_person_designation: Yup.string().matches(
        departmentRegExp,
        "Please enter valid designation"
      ),
      // .required("Meeting person designation is required"),
      meeting_person_department: Yup.string()
        .matches(departmentRegExp, "Please enter valid department")
        .required("Meeting person department is required"),
      locationId: Yup.string(),
      companyOrBuildingLocations: Yup.string().required(
        `${
          companyData.type == "Company"
            ? "Please select Location"
            : "Please select building"
        }`
      ),
      // lat: Yup.string().required("You must enable location to move forward"),
      // long: Yup.string().required("You must enable location to move forward"),
    }),

    onSubmit: (values) => {
      if (phoneErr) {
        return;
      }
      let visitorData = { ...values };
      if (values.companyOrBuildingLocations !== "Dummy") {
        //For multiple location
        dispatch(
          visitorActions.setMultipleLocationId(
            values.companyOrBuildingLocations
          )
        );
        localStorage.setItem(
          "multipleLocationId",
          values.companyOrBuildingLocations
        );
        visitorData = {
          ...values,
          locationId: values.companyOrBuildingLocations,
        };
      } else {
        if (locations.length) {
          //For single location
          visitorData = {
            ...values,
            locationId: locations[0]._id,
          };
        } else {
          toast.error("No location added for this company", {
            position: "top-center",
          });
          return;
        }
      }

      if (!visitorData.locationId) {
        delete visitorData.locationId;
      }
      if (!visitorData.meeting_person_designation) {
        delete visitorData.meeting_person_designation;
      }
      dispatch(
        visitorActions.setVisitorFormData({
          ...JSON.parse(localStorage.getItem("visitorFormData")),
          ...visitorData,
        })
      );
      localStorage.setItem(
        "visitorFormData",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("visitorFormData")),
          ...visitorData,
        })
      );
      navigate("/selfie");
    },
  });

  const handleMeetingPersonData = (phone) => {
    formik.setFieldValue("meeting_person_phone", phone);
    if (phoneRegExp.test(phone) && phone.length !== 10) {
      setTimeout(() => {
        formik.setFieldError(
          "meeting_person_phone",
          "Please enter valid phone number"
        );
      }, 0);
      return;
    }

    if (!companyData.employeeDirectory) {
      return;
    }

    if (phone.length !== 10) {
      formik.setFieldValue("meeting_person_name", "");
      formik.setFieldValue("meeting_person_designation", "");
      formik.setFieldValue("meeting_person_department", "");
      return;
    }
    if (
      formik.values.companyOrBuildingLocations !== "Dummy" &&
      formik.values.companyOrBuildingLocations == ""
    ) {
      toast.error(
        "Please select location before entering meeting person number",
        {
          position: "top-center",
        }
      );
      return;
    }

    let reqObj = {
      method: "GET",
      // url: `${base_url}/visitor/search/employee/${phone}/${companyData._id}/${formik.values.companyOrBuildingLocations}`,
      url: `${base_url}/visitor/search/employee/${phone}/${companyData._id}/${formik.values.companyOrBuildingLocations}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(reqObj)
      .then((res) => {
        if (!res["data"]["data"]) {
          setPhoneErr("Meeting person phone number does not exist");
          throw new Error("Meeting person phone number does not exist");
        }
        setPhoneErr(false);
        formik.setFieldValue("meeting_person_name", res.data.data.name);
        formik.setFieldValue(
          "meeting_person_designation",
          res.data.data.designation
        );
        formik.setFieldValue(
          "meeting_person_department",
          res.data.data.department
        );
        setTimeout(() => {
          formik.setFieldError("meeting_person_name", "");
          formik.setFieldError("meeting_person_designation", "");
          formik.setFieldError("meeting_person_department", "");
        }, 0);
        formik.setFieldValue("locationId", res.data.data.locationId);
      })
      .catch((err) => {
        console.log(err);
        setTimeout(() => {
          formik.setFieldError(
            "meeting_person_phone",
            "Meeting person phone number does not exist"
          );
        }, 0);
      });
  };

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="wrapper-mid" style={{ background: "none" }}>
            <div className={`${classes.container}`}>
              <form onSubmit={formik.handleSubmit}>
                <p>Please enter your details</p>
                <hr className={classes.hr2} />

                <div className={classes.inputField}>
                  <label>First Name</label>
                  <input
                    placeholder="First Name"
                    id="firstName"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div>{formik.errors.firstName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Last Name</label>
                  <input
                    placeholder="Last Name"
                    id="lastName"
                    name="lastName"
                    {...formik.getFieldProps("lastName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div>{formik.errors.lastName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Company Name</label>
                  <input
                    placeholder="Company Name"
                    id="companyName"
                    name="companyName"
                    {...formik.getFieldProps("companyName")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div>{formik.errors.companyName}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    id="Address"
                    name="Address"
                    {...formik.getFieldProps("Address")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.Address && formik.errors.Address ? (
                      <div>{formik.errors.Address}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Purpose of Visit</label>
                  <input
                    placeholder="Purpose of Visit"
                    id="Purpose"
                    name="Purpose"
                    {...formik.getFieldProps("Purpose")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.Purpose && formik.errors.Purpose ? (
                      <div>{formik.errors.Purpose}</div>
                    ) : null}
                  </span>
                </div>

                {companyData.noOfLocation !== 1 && (
                  <div className={classes.inputField}>
                    <label>
                      {companyData.type == "Company"
                        ? "Select Location"
                        : "Select Company"}
                    </label>
                    <select
                      placeholder={
                        companyData.type == "Company"
                          ? "Select Location"
                          : "Select Company"
                      }
                      id="companyOrBuildingLocations"
                      name="companyOrBuildingLocations"
                      {...formik.getFieldProps("companyOrBuildingLocations")}
                    >
                      <option value="">Select</option>
                      {locations &&
                        locations.map((loc) => {
                          return (
                            <option value={loc._id}>{loc.location}</option>
                          );
                        })}
                    </select>
                    <span className={classes.errMsg}>
                      {formik.touched.companyOrBuildingLocations &&
                      formik.errors.companyOrBuildingLocations ? (
                        <div>{formik.errors.companyOrBuildingLocations}</div>
                      ) : null}
                    </span>
                  </div>
                )}

                <div className={classes.inputField}>
                  <label>Meeting Person Mobile Number</label>
                  <input
                    placeholder="Meeting Person Mobile Number"
                    id="meeting_person_phone"
                    name="meeting_person_phone"
                    onChange={(e) => handleMeetingPersonData(e.target.value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.meeting_person_phone}
                  />
                  {phoneErr ? (
                    <span className={classes.errMsg}>
                      <div>{phoneErr}</div>
                    </span>
                  ) : (
                    <span className={classes.errMsg}>
                      {formik.touched.meeting_person_phone &&
                      formik.errors.meeting_person_phone ? (
                        <div>{formik.errors.meeting_person_phone}</div>
                      ) : null}
                    </span>
                  )}
                </div>

                <div className={classes.inputField}>
                  <label>Meeting Person Name</label>
                  <input
                    placeholder="Meeting Person Name"
                    id="meeting_person_name"
                    name="meeting_person_name"
                    {...formik.getFieldProps("meeting_person_name")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_name &&
                    formik.errors.meeting_person_name ? (
                      <div>{formik.errors.meeting_person_name}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Designation(optional)</label>
                  <input
                    placeholder="Designation"
                    id="meeting_person_designation"
                    name="meeting_person_designation"
                    {...formik.getFieldProps("meeting_person_designation")}
                  />
                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_designation &&
                    formik.errors.meeting_person_designation ? (
                      <div>{formik.errors.meeting_person_designation}</div>
                    ) : null}
                  </span>
                </div>

                <div className={classes.inputField}>
                  <label>Department</label>
                  {/* <input
                    placeholder="Department"
                    id="meeting_person_department"
                    name="meeting_person_department"
                    {...formik.getFieldProps("meeting_person_department")}
                  /> */}
                  <select
                    placeholder="Select Department"
                    id="meeting_person_department"
                    name="meeting_person_department"
                    {...formik.getFieldProps("meeting_person_department")}
                  >
                    <option value="">Select</option>

                    {Departments.map((department) => {
                      return <option value={department}>{department}</option>;
                    })}
                    {/* <option value="Sales">Sales</option>
                    <option value="Operations">Operations</option>
                    <option value="Product">Product</option>
                    <option value="Tech & IT">Tech & IT</option>
                    <option value="Quality">Quality</option>
                    <option value="Purchase">Purchase</option>
                    <option value="Procurement">Procurement</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Admin">Admin</option>
                    <option value="HR">HR</option>
                    <option value="Finance">Finance</option>
                    <option value="Legal">Legal</option>
                    <option value="Others">Others</option> */}
                  </select>
                  <span className={classes.errMsg}>
                    {formik.touched.meeting_person_department &&
                    formik.errors.meeting_person_department ? (
                      <div>{formik.errors.meeting_person_department}</div>
                    ) : null}
                  </span>

                  <span className={classes.errMsg}>
                    {formik.errors.lat ? <div>{formik.errors.lat}</div> : null}
                  </span>
                </div>

                <button
                  type="submit"
                  className={classes.btn}
                  onClick={() => {
                    if (Object.keys(formik.errors).length) {
                      console.log(formik.errors);
                      toast.dismiss();
                      toast.error("Please fill required details", {
                        position: "top-center",
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </form>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
