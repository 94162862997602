import Home from "../Home/Home";
import Profile from "./Profile";
import { useState } from "react";
import { useContext, useEffect } from "react";
import axios from "axios";
import authContext from "../../../AuthContext/authContext";
const base_url = process.env.REACT_APP_BASE_URL;

const ProfileWrapper = () => {
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [ProfileName, setName] = useState("");
  const [ProfilEmail, setEmail] = useState("");
  const [ProfilePhone, setPhone] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/user/myprofile`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setName(res.data.name);
        setEmail(res.data.email);
        setPhone(res.data.phone);
        setUserId(res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Home
        propComponent={
          <Profile
            ProfileName={ProfileName}
            ProfilEmail={ProfilEmail}
            ProfilePhone={ProfilePhone}
            userId={userId}
            token={token}
          />
        }
      />
    </>
  );
};

export default ProfileWrapper;
