import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passwordId: localStorage.getItem("passwordId"),
  visitorPhone: Number(localStorage.getItem("visitorPhone")),
  visitorFormData: JSON.parse(localStorage.getItem("visitorFormData")),
  companyData: JSON.parse(localStorage.getItem("companyData")),
  multipleLocationId: localStorage.getItem("multipleLocationId"),
  selfie: "",
  doc1: "",
  doc2: "",
  activeCamera: "",
  OTP: "",
  OTPTime: localStorage.getItem("OTPTime"),
  facingMode: "user",
};
export const visitorSlice = createSlice({
  name: "visitorSlice",
  initialState,
  reducers: {
    setVisitorFormData(state, action) {
      state.visitorFormData = action.payload;
    },
    setSelfie(state, action) {
      state.selfie = action.payload;
    },
    setActiveCamera(state, action) {
      state.activeCamera = action.payload;
    },
    setDoc1(state, action) {
      state.doc1 = action.payload;
    },
    setDoc2(state, action) {
      state.doc2 = action.payload;
    },
    setCompanyData(state, action) {
      state.companyData = action.payload;
    },
    setVisitorPhone(state, action) {
      state.visitorPhone = action.payload;
    },
    setMultipleLocationId(state, action) {
      state.multipleLocationId = action.payload;
    },
    setOTP(state, action) {
      state.OTP = action.payload;
    },
    setOTPTime(state, action) {
      state.OTPTime = action.payload;
    },
    setFacingMode(state, action) {
      state.facingMode = action.payload;
    },
  },
});

const visitorActions = visitorSlice.actions;

export default visitorActions;
