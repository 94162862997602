import formclasses from "../AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import classes from "../AddNewCompany/AddNewCompany.module.css";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import ReactFileReader from "react-file-reader";
import "react-datepicker/dist/react-datepicker.css";
import authContext from "../../../AuthContext/authContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const base_url = process.env.REACT_APP_BASE_URL;
const AddNewBuilding = () => {
  const addCompanyOrBuilding = useSelector(
    (state) => state.appData.addCompanyOrBuilding
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [preview, setPreview] = useState();
  const ctx = useContext(authContext);
  const token = ctx.token;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z ]+$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: "",
      // company_type: "",
      manager_name: "",
      manager_email: "",
      manager_phone: "",
      password: "",
      storage_duration: "",
      start_date: "",
      end_date: "",
      allowed_location: 500,
      location_switch: true,
      visitor_verification: false,
      Bgcolor: "#ffffff",
      logo: undefined,
      footer_switch: false,
      footer_line_1: "",
      footer_line_2: "",
      directory_switch: false,
      que1: "",
      ans1: "",
      que2: "",
      ans2: "",
      que3: "",
      ans3: "",
      que4: "",
      ans4: "",
      que5: "",
      ans5: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please enter your name"),
      // company_type: Yup.string().required("Please enter company type"),
      manager_name: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter company manager name"),
      manager_email: Yup.string()
        .email("Invalid email address")
        .required("Please enter company manager email address"),
      manager_phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter company manager phone no."),

      password: Yup.string()
        .min(8, "password must be 8 characters long")
        .required("Please enter your new password"),
      storage_duration: Yup.string().required(
        "Please enter data storage duration"
      ),
      start_date: Yup.string().required("Please enter start date"),
      end_date: Yup.string().required("Please enter end date"),
      logo: Yup.mixed(),
      que1: Yup.string(),
      ans1: Yup.string(),
      que2: Yup.string(),
      ans2: Yup.string(),
      que3: Yup.string(),
      ans3: Yup.string(),
      que4: Yup.string(),
      ans4: Yup.string(),
      que5: Yup.string(),
      ans5: Yup.string(),
      location_switch: Yup.boolean(),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.company_name);
      formData.append("type", "Building");
      formData.append("managerName", values.manager_name);
      formData.append("managerEmail", values.manager_email);
      formData.append("managerPhone", values.manager_phone);
      formData.append("userType", "Building Manager");
      formData.append("password", values.password);
      formData.append("storageDuration", values.storage_duration);
      formData.append(
        "subscriptionStartDate",
        values.start_date.toISOString().slice(0, 10)
      );
      formData.append(
        "subscriptionEndDate",
        values.end_date.toISOString().slice(0, 10)
      );
      formData.append("location", values.location_switch);
      formData.append("noOfLocation", values.allowed_location);
      formData.append("visitorVerification", values.visitor_verification);
      formData.append("backgroundColor", values.Bgcolor);
      // formData.append("logoFile", values.logo);
      if (values.logo) {
        formData.append("logoFile", values.logo);
      }
      formData.append("footerStatus", values.footer_switch);
      // formData.append(
      //   "footerTextOne",
      //   values.footer_switch ? values.footer_line_1 : ""
      // );
      // formData.append(
      //   "footerTextTwo",
      //   values.footer_switch ? values.footer_line_2 : ""
      // );
      if (values.footer_switch) {
        formData.append("footerTextOne", values.footer_line_1);
        formData.append("footerTextTwo", values.footer_line_2);
      }
      formData.append("employeeDirectory", values.directory_switch);
      formData.append("smsCount", 0);

      if (values.que1 !== "") {
        formData.append("covidQuestionOne", values.que1);
        formData.append("covidAnswerOne", values.ans1);
      }
      if (values.que2 !== "") {
        formData.append("covidQuestionTwo", values.que2);
        formData.append("covidAnswerTwo", values.ans2);
      }
      if (values.que3 !== "") {
        formData.append("covidQuestionThree", values.que3);
        formData.append("covidAnswerThree", values.ans3);
      }
      if (values.que4 !== "") {
        formData.append("covidQuestionFour", values.que4);
        formData.append("covidAnswerFour", values.ans4);
      }
      if (values.que5 !== "") {
        formData.append("covidQuestionFive", values.que5);
        formData.append("covidAnswerFive", values.ans5);
      }

      axios
        .post(`${base_url}/company/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          formik.resetForm();
          setStartDate("");
          setEndDate("");
          toast.dismiss();
          toast.success("Building Created Successfully!!");
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(
            err.response.data.error.message || "Failed to create Building"
          );
        });
    },
  });

  const handleFile = (file) => {
    setPreview(file.base64);
    formik.setFieldValue("logo", file.fileList[0]);
  };

  return (
    <>
      <div className={`${formclasses.mainCont} ${classes.Container}`}>
        <h3>Add New Building</h3>

        <form className={formclasses.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={formclasses.formGrp}>
            <label for="company_name">Building Name:</label>
            <div>
              <input
                type="text"
                id="company_name"
                name="company_name"
                {...formik.getFieldProps("company_name")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.company_name && formik.errors.company_name ? (
                  <div>{formik.errors.company_name}</div>
                ) : null}
              </span>
            </div>
          </div>
          {/* <div className={formclasses.formGrp}>
            <label for="company_type">Company Type:</label>
            <div>
              <input
                type="text"
                id="company_type"
                name="company_type"
                {...formik.getFieldProps("company_type")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.company_type && formik.errors.company_type ? (
                  <div>{formik.errors.company_type}</div>
                ) : null}
              </span>
            </div>
          </div> */}
          <div className={formclasses.formGrp}>
            <label for="manager_name">Building Manager Name: </label>
            <div>
              <input
                type="text"
                id="manager_name"
                name="manager_name"
                {...formik.getFieldProps("manager_name")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.manager_name && formik.errors.manager_name ? (
                  <div>{formik.errors.manager_name}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={formclasses.formGrp}>
            <label for="manager_email">Building Manager Email: </label>
            <div>
              <input
                type="text"
                id="manager_email"
                name="manager_email"
                {...formik.getFieldProps("manager_email")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.manager_email && formik.errors.manager_email ? (
                  <div>{formik.errors.manager_email}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={formclasses.formGrp}>
            <label for="manager_phone">Building Manager Phone:</label>
            <div>
              <input
                type="text"
                id="manager_phone"
                name="manager_phone"
                {...formik.getFieldProps("manager_phone")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.manager_phone && formik.errors.manager_phone ? (
                  <div>{formik.errors.manager_phone}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={formclasses.formGrp}>
            <label for="password">Password:</label>
            <div>
              <input
                type="password"
                id="password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.password && formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={formclasses.formGrp}>
            <label for="storage_duration">Data Duration Storage:</label>
            <div>
              <select
                id="storage_duration"
                name="storage_duration"
                className={classes.dropDown}
                {...formik.getFieldProps("storage_duration")}
                placeholder="Select"
              >
                <option value="" disabled selected>
                  Select your option
                </option>
                <option value="1 Month">1 Month</option>
                <option value="3 Month">3 Month</option>
                <option value="6 Month">6 Month</option>
                <option value="6 Month">9 Month</option>
                <option value="12 Month">12 Month</option>
              </select>
              <span className={formclasses.profileerrMsg}>
                {formik.touched.storage_duration &&
                formik.errors.storage_duration ? (
                  <div>{formik.errors.storage_duration}</div>
                ) : null}
              </span>
            </div>

            {/* <input type="text" id="lname" name="lname" /> */}
          </div>
          <div
            className={`${formclasses.formGrp}`}
            style={{ display: "flex", margin: "0.8rem 0 0.8rem 0" }}
          >
            <label for="subscription">Subscription:</label>
            <span>
              <span className={classes.dateDiv}>
                <DatePicker
                  id="start_date"
                  name="start_date"
                  showYearDropdown
                  minDate={new Date()}
                  placeholderText="Start Date"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    formik.setFieldValue("start_date", date);
                  }}
                  // onChange={(date) => {
                  //   formik.setFieldValue("start_date", date.getDate());
                  // }}
                  onBlur={formik.handleBlur}
                  value={startDate}
                  // customInput={<>Hi</>}
                  className={classes.customDate}
                />

                <div
                  style={{
                    width: "10%",
                    height: "0px",
                    backgroundColor: "black",
                    border: "1px solid black",
                    margin: "0 12px 0 0px",
                  }}
                ></div>

                <DatePicker
                  id="end_date"
                  name="end_date"
                  showYearDropdown
                  placeholderText="End Date"
                  selected={endDate}
                  minDate={new Date(formik.values.start_date + 1)}
                  onChange={(date) => {
                    setEndDate(date);
                    formik.setFieldValue("end_date", date);
                  }}
                  // onChange={(date) => {
                  //   formik.setFieldValue("start_date", date.getDate());
                  // }}
                  onBlur={formik.handleBlur}
                  value={startDate}
                  className={classes.customDate}
                />
              </span>
              <span
                className={formclasses.profileerrMsg}
                style={{ marginLeft: "1.5rem", color: "rgb(223, 58, 58)" }}
              >
                {(formik.touched.start_date && formik.errors.start_date) ||
                (formik.touched.end_date && formik.errors.end_date) ? (
                  <div>
                    {formik.errors.start_date || formik.errors.end_date}
                  </div>
                ) : null}
              </span>
            </span>
          </div>

          <div className={classes.toggleSwitch}>
            <div className="form-check form-switch">
              <label className="form-check-label" for="visitor_verification">
                Visitor Verification
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="visitor_verification"
                name="visitor_verification"
                {...formik.getFieldProps("visitor_verification")}
              />
            </div>
          </div>
          <div className={classes.colorDiv}>
            <div className={classes.pickerCont}>
              <label for="Bgcolor">Background Color</label>
              <div className={classes.picker}>
                <input
                  type="color"
                  id="Bgcolor"
                  name="Bgcolor"
                  // value="#ff0000"
                  // defaultValue="#ffffff"
                  {...formik.getFieldProps("Bgcolor")}
                />
                <img src={require("../../assets/img/downIcon.png")} />
              </div>
            </div>
            {/* <ReactFileReader
              name="logo"
              fileTypes={[".png", ".jpg", ".jpeg"]}
              handleFiles={handleFile}
              base64={true}
            > */}
            <div className={classes.logoDiv}>
              <label for="logo">Logo</label>

              <ReactFileReader
                name="logo"
                fileTypes={[".png", ".jpg", ".jpeg"]}
                handleFiles={handleFile}
                base64={true}
              >
                <div className={`${classes.uploadBtnWrapper}`}>
                  <button type="button" className={`${classes.btn}`}>
                    <img src={require("../../assets/img/upload.png")} />
                    <div>Select</div>
                  </button>
                  {/* <input type="file" name="myfile" /> */}
                </div>
              </ReactFileReader>

              <div className={classes.previewLogo}>
                {preview && <img src={preview} />}
              </div>

              <div style={{ display: "block", color: "rgb(223, 58, 58)" }}>
                {formik.touched.logo && formik.errors.logo ? (
                  <div>{formik.errors.logo}</div>
                ) : null}
              </div>
            </div>
            {/* </ReactFileReader> */}
            {/* <div className={classes.previewLogo}>
              {preview && <img src={preview} />}
            </div> */}
          </div>
          <div className={classes.toggleSwitch}>
            <div className="form-check form-switch">
              <label className="form-check-label" for="footer_switch">
                Footer
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="footer_switch"
                name="footer_switch"
                {...formik.getFieldProps("footer_switch")}
              />
            </div>
          </div>
          {formik.values.footer_switch && (
            <>
              <input
                className={classes.footerInpDiv}
                placeholder="Powered by Fedicoms Pvt Ltd."
                id="footer_line_1"
                name="footer_line_1"
                {...formik.getFieldProps("footer_line_1")}
              />{" "}
              <br />
              <input
                className={classes.footerInpDiv}
                placeholder="info@fedicoms.com"
                id="footer_line_2"
                name="footer_line_2"
                {...formik.getFieldProps("footer_line_2")}
              />
            </>
          )}
          <div className={classes.toggleSwitch}>
            <div className="form-check form-switch">
              <label className="form-check-label" for="directory_switch">
                Employee Directory
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="directory_switch"
                name="directory_switch"
                {...formik.getFieldProps("directory_switch")}
              />
            </div>
          </div>
          <hr className={classes.hr1} />
          <div>
            <h3>Add Covid Questions</h3>
            <div>
              <input
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Q1. Add Title"
                id="que1"
                name="que1"
                {...formik.getFieldProps("que1")}
              />
              <textarea
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Answer"
                id="ans1"
                name="ans1"
                {...formik.getFieldProps("ans1")}
              />
              <span className={formclasses.profileerrMsg}>
                {formik.touched.ans1 && formik.errors.ans1 ? (
                  <div>{formik.errors.ans1}</div>
                ) : null}
              </span>
            </div>
            <hr className={classes.hr1} style={{ width: "80%" }} />

            <div>
              <input
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Q2. Add Title"
                id="que2"
                name="que2"
                {...formik.getFieldProps("que2")}
              />
              <textarea
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Answer"
                id="ans2"
                name="ans2"
                {...formik.getFieldProps("ans2")}
              />
            </div>
            <hr className={classes.hr1} style={{ width: "80%" }} />

            <div>
              <input
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Q3. Add Title"
                id="que3"
                name="que3"
                {...formik.getFieldProps("que3")}
              />
              <textarea
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Answer"
                id="ans3"
                name="ans3"
                {...formik.getFieldProps("ans3")}
              />
            </div>
            <hr className={classes.hr1} style={{ width: "80%" }} />

            <div>
              <input
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Q4. Add Title"
                id="que4"
                name="que4"
                {...formik.getFieldProps("que4")}
              />
              <textarea
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Answer"
                id="ans4"
                name="ans4"
                {...formik.getFieldProps("ans4")}
              />
            </div>
            <hr className={classes.hr1} style={{ width: "80%" }} />

            <div>
              <input
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Q5. Add Title"
                id="que5"
                name="que5"
                {...formik.getFieldProps("que5")}
              />
              <textarea
                className={`${classes.footerInpDiv} ${classes.covidQue}`}
                placeholder="Answer"
                id="ans5"
                name="ans5"
                {...formik.getFieldProps("ans5")}
              />
            </div>
          </div>
          <div className={formclasses.formBtn}>
            {/* <button className={formclasses.cancleBtn}>Cancel</button> */}
            <button type="submit" className={formclasses.createBtn}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewBuilding;
