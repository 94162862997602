import axios from "axios";
import { useEffect } from "react";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authContext from "../../../AuthContext/authContext";
import appActions from "../../../ReduxStore/appDataSlice";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Home from "../Home/Home";
import Table from "../Table/Table";
import classes from "../Table/Table.module.css";
const base_url = process.env.REACT_APP_BASE_URL;
const EmployeeDirectoryWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const activeLocation = useSelector((state) => state.appData.activeLocation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  const token = ctx.token;
  const [isLoading, setIsloading] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState({});
  const handleEdit = (id) => {
    dispatch(appActions.setActiveEditEmployeeId(id));
    localStorage.setItem("activeEditEmployeeId", id);
    navigate("/editEmployee");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${base_url}/company/delete-employee/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
        toast.success("Employee deleted successfully.");
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
        toast.error("Failed to delete employee");
      });
  };

  useEffect(() => {
    setIsloading(true);
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/details/${activeCompany._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        if (!res.data.employeeDirectory) {
          navigate("/dashboard");
          toast.error("Employee directory if OFF");
        }
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  }, []);

  const columns = [
    {
      name: "S. No.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => (
        <p data-toggle="tooltip" data-placement="top" title={row.name}>
          {row.name}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <p data-toggle="tooltip" data-placement="top" title={row.email}>
          {row.email}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.department,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div className={classes.icons}>
            {/* <img src={require("../../assets/img/eye.png")} /> */}
            <img
              style={{ cursor: "pointer" }}
              src={require("../../assets/img/edit_thin.png")}
              onClick={() => handleEdit(row._id)}
            />
            <img
              style={{ cursor: "pointer" }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              src={require("../../assets/img/delete.png")}
              // onClick={() => handleDelete(row._id)}
              onClick={() =>
                setEmployeeToDelete({ id: row._id, name: row.name })
              }
            />
          </div>
        </>
      ),
    },
  ];
  let showAddNew = true;
  let showCsvExport = true;
  let showDownload = true;
  let showExport = true;
  let showLocation = true;
  let showSms = false;
  let showFirstHr = true;
  let showSecondHr = false;
  let showActions = true;
  let showVisitor = true;

  if (userType === "location") {
    showLocation = false;
  }

  const options = {
    title: `Employee-${activeLocation.location}`,
    showCsvExport: showCsvExport,
    showDownload: showDownload,
    showExport: showExport,
    showLocation: showLocation,
    showSms: showSms,
    showAddNew: showAddNew,
    showFirstHr: showFirstHr,
    showSecondHr: showSecondHr,
    showActions: showActions,
    type: "EmployeeDirectory",
    columns: columns,
    apiUrl: `${base_url}/company/employees`,
    companyId: activeCompany._id,
    locationId: activeLocation._id,
    showVisitor: showVisitor,
  };
  return (
    <>
      <Home propComponent={<Table options={options} isLoading={isLoading} />} />
      <ConfirmModal
        handleDelete={handleDelete}
        id={employeeToDelete.id}
        msg={`Are you sure you want to delete ${employeeToDelete.name}?`}
      />
    </>
  );
};

export default EmployeeDirectoryWrapper;
