import Dashboard from "./Dashboard";
import Home from "../Home/Home";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import authContext from "../../../AuthContext/authContext";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../Loader/Loader";
import appActions from "../../../ReduxStore/appDataSlice";
const base_url = process.env.REACT_APP_BASE_URL;
const DashboardWrapper = () => {
  // const activeCompany = useSelector((state) => state.appData.activeCompany);
  const dispatch = useDispatch();
  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  let initialCompanyId = "";
  let initialLocationId = "";
  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager" ||
    userType === "company"
  ) {
    initialCompanyId = ctx.userDetailedData.company_id;
    dispatch(appActions.setBarChartPayload({ companyId: initialCompanyId }));
    localStorage.setItem(
      "barChartPayload",
      JSON.stringify({ companyId: initialCompanyId })
    );
    if (userType === "location") {
      initialLocationId = ctx.userDetailedData._id;
      dispatch(
        appActions.setBarChartPayload({
          companyId: initialCompanyId,
          locationId: initialLocationId,
        })
      );
      localStorage.setItem(
        "barChartPayload",
        JSON.stringify({
          companyId: initialCompanyId,
          locationId: initialLocationId,
        })
      );
    }
    if (userType === "company") {
      initialLocationId = ctx.userDetailedData._id;
    }
  } else {
    initialCompanyId = "";
  }

  // 64019334c5873c36bcafccb5
  // 640076ca0d26652a18de2129

  const [companyId, setCompanyId] = useState(initialCompanyId);
  const [initialData, setInitialData] = useState();
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  let isCompanyLoaded = false;
  if (
    userType === "Company Manager" ||
    userType === "location" ||
    userType === "Building Manager" ||
    userType === "company"
  ) {
    isCompanyLoaded = true;
  }
  const [companiesLoaded, setCompaniesLoaded] = useState(isCompanyLoaded);
  const [frequency, setFrequency] = useState(7);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    setDashboardLoaded(false);
    let data = {
      companyId: companyId,
      frequency: Number(frequency),
    };
    if (userType === "location") {
      data = { ...data, locationId: initialLocationId };
    }

    let reqObj = {
      method: "POST",
      url: `${base_url}/dashboard/statistics`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ctx.token}`,
      },
      data: data,
    };

    axios(reqObj)
      .then((res) => {
        setInitialData(res.data);
        setDashboardLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setDashboardLoaded(true);
      });
  }, [frequency, companyId]);

  useEffect(() => {
    if (userType == "admin" || userType == "subadmin") {
      setCompaniesLoaded(false);
      // /company/companylist
      let reqObj = {
        method: "GET",
        url: `${base_url}/company/companylist`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`,
        },
      };

      axios(reqObj)
        .then((res) => {
          setCompanyList(res.data);
          setCompanyId(res.data[0]["_id"]);
          setCompaniesLoaded(true);

          dispatch(
            appActions.setBarChartPayload({ companyId: res.data[0]["_id"] })
          );
          localStorage.setItem(
            "barChartPayload",
            JSON.stringify({ companyId: res.data[0]["_id"] })
          );
        })
        .catch((err) => {
          console.log(err);
          setCompaniesLoaded(true);
        });
    }
  }, []);

  return (
    <>
      <Home
        propComponent={
          <>
            {/* {dashboardLoaded && companiesLoaded ? ( */}
            <Dashboard
              initialData={initialData || {}}
              setCompanyId={setCompanyId}
              setFrequency={setFrequency}
              companyList={companyList || []}
              isLoading={dashboardLoaded && companiesLoaded}
            />
            {/* ) : (
               <Loader />
             )} */}
          </>
        }
        // isLoading={!dashboardLoaded && !companiesLoaded}
      />
    </>
  );
};

export default DashboardWrapper;
