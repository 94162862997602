import tableClass from "../Table/Table.module.css";
import classes from "../AddEmployeeDirectory/AddEmployeeDirectory.module.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext } from "react";
import authContext from "../../../AuthContext/authContext";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const AddCompanyInBuilding = () => {
  const ctx = useContext(authContext);
  const user_id = ctx.userData.user_id;
  const activeBuilding = useSelector((state) => state.appData.activeBuilding);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const nameRegExp = /^[A-Za-z ]+$/;
  const formik = useFormik({
    initialValues: {
      location: "",
      manager_name: "",
      manager_email: "",
      manager_phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      location: Yup.string().required("Please enter company name"),
      manager_name: Yup.string()
        .matches(nameRegExp, "Please enter valid name")
        .required("Please enter company manager name"),
      manager_email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email address"),
      manager_phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
      password: Yup.string()
        .min(8, "password must be 8 characters long")
        .required("Please enter a password"),
    }),
    onSubmit: (values) => {
      let reqObj = {
        method: "POST",
        url: `${base_url}/auth/register`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ctx.token}`,
        },
        data: {
          email: values.manager_email,
          phone: values.manager_phone,
          name: values.manager_name,
          password: values.password,
          user_type: "company",
          created_by: user_id,
          location: values.location, //company name
          company_id: activeBuilding._id, //Building ID
        },
      };

      axios(reqObj)
        .then((res) => {
          toast.dismiss();
          toast.success("Company created Successfully!!");
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err);
          toast.dismiss();
          toast.error(
            err.response.data.error.message || "Failed to create company"
          );
        });
    },
  });

  return (
    <>
      <div className={classes.mainCont}>
        <h3>Add New Company</h3>
        {/* <div className={classes.topLinks}>
          <div className={tableClass.tableAction}>
            <Link to="/employeeDirectory">
              <img src={require("../../assets/img/copy.png")} />
              <span>Employee Directory</span>
            </Link>
          </div>
          <div className={tableClass.tableAction}>
            <Link to="/location">
              <img src="/static/img/company_black.png" />
              <span>Location</span>
            </Link>
          </div>
        </div> */}

        <form className={classes.FormDiv} onSubmit={formik.handleSubmit}>
          <div className={classes.formGrp}>
            <label for="location">Comapany Name:</label>
            <div>
              <input
                type="text"
                id="location"
                name="location"
                {...formik.getFieldProps("location")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.location && formik.errors.location ? (
                  <div>{formik.errors.location}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="manager_name">Comapany Manager Name:</label>

            <div>
              <input
                type="text"
                id="manager_name"
                name="manager_name"
                {...formik.getFieldProps("manager_name")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.manager_name && formik.errors.manager_name ? (
                  <div>{formik.errors.manager_name}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formGrp}>
            <label for="manager_email">Comapany Manager Email: </label>

            <div>
              <input
                type="text"
                id="manager_email"
                name="manager_email"
                {...formik.getFieldProps("manager_email")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.manager_email && formik.errors.manager_email ? (
                  <div>{formik.errors.manager_email}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="manager_phone">Comapany Manager Phone:</label>

            <div>
              <input
                type="text"
                id="manager_phone"
                name="manager_phone"
                {...formik.getFieldProps("manager_phone")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.manager_phone && formik.errors.manager_phone ? (
                  <div>{formik.errors.manager_phone}</div>
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.formGrp}>
            <label for="password">Password:</label>

            <div>
              <input
                type="password"
                id="password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              <span className={classes.profileerrMsg}>
                {formik.touched.password && formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </span>
            </div>
          </div>

          <div className={classes.formBtn}>
            {/* <button className={classes.cancleBtn}>Cancel</button> */}
            <button type="submit" className={classes.createBtn}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCompanyInBuilding;
