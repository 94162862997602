import Home from "../Home/Home";
import VisitorDetails from "./VisitorDetails";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import authContext from "../../../AuthContext/authContext";
const base_url = process.env.REACT_APP_BASE_URL;

const VisitorDetailsWrapper = () => {
  const activeVisitor = useSelector((state) => state.appData.activeVisitor);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/visitor/details/${activeVisitor._id}`,
      headers: {
        "Content-Type": "application/javascript",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Home propComponent={<VisitorDetails initialData={initialData} />} />
    </>
  );
};

export default VisitorDetailsWrapper;
