import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Home from "../Home/Home";
import Terms from "./Terms";
import authContext from "../../../AuthContext/authContext";
import { useContext } from "react";
const base_url = process.env.REACT_APP_BASE_URL;

const TermsWrapper = () => {
  const activeCompany = useSelector((state) => state.appData.activeCompany);
  const ctx = useContext(authContext);
  const token = ctx.token;
  const [initialData, setInitialData] = useState({});
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    let reqObj = {
      method: "GET",
      url: `${base_url}/company/terms/${activeCompany._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(reqObj)
      .then((res) => {
        setInitialData({ ...res.data });
        setDomLoaded(true);
      })
      .catch((err) => {
        setDomLoaded(true);
        console.log(err);
      });
  }, []);
  return (
    <>
      <Home propComponent={domLoaded && <Terms initialData={initialData} />} />
    </>
  );
};

export default TermsWrapper;
