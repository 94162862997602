import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Login.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import visitorActions from "../../../ReduxStore/visitorSlice";
import Footer from "../Footer/Footer";
import "../../assets/css/master.css";
import { toast } from "react-toastify";
const base_url = process.env.REACT_APP_BASE_URL;
const moment = require("moment");

const VisitorLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [subscriptionErr, setSubscriptionErr] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const companyId = location.pathname.substring(9, location.pathname.length);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  useEffect(() => {
    // 64019334c5873c36bcafccb5
    // 640076ca0d26652a18de2129
    // 640076ca0d26652a18de2129
    // http://localhost:3000/visitor/company/details/63f6f30204d53824f24bcf8a
    axios(`${base_url}/visitor/company/details/${companyId}`)
      .then((res) => {
        dispatch(visitorActions.setCompanyData({ ...res.data.data }));
        localStorage.setItem(
          "companyData",
          JSON.stringify({ ...res.data.data })
        );

        const startDate = moment(
          res.data.data.subscriptionStartDate.substring(0, 10),
          "YYYY-MM-DD"
        );
        const endDate = moment(
          res.data.data.subscriptionEndDate.substring(0, 10),
          "YYYY-MM-DD"
        );
        const currentDate = moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");

        if (currentDate.isBefore(startDate)) {
          setSubscriptionErr("Subsciption is yet to start");
          toast.error("Subsciption is yet to start", {
            position: "top-center",
          });
        }

        if (currentDate.isAfter(endDate)) {
          setSubscriptionErr("Subsciption is expired");
          toast.error("Subsciption is expired", {
            position: "top-center",
          });
        }
      })
      .catch((err) => {
        setDisabled(true);
      });
  }, []);

  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const companyData = useSelector((state) => state.visitorData.companyData);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setLat(pos.coords.latitude);
      setLon(pos.coords.longitude);
      localStorage.setItem(
        "visitorFormData",
        JSON.stringify({ lat: pos.coords.latitude, long: pos.coords.longitude })
      );
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Phone number should be of 10 digits")
        .required("Please enter your phone number"),
    }),
    onSubmit: (values) => {
      if (lat == "" || lat == "undefined") {
        toast.error("Please allow location to move forward", {
          position: "top-center",
        });
        return;
      }

      if (subscriptionErr) {
        toast.error(subscriptionErr, {
          position: "top-center",
        });
        return;
      }
      setLoading(true);
      let reqObj = {
        method: "POST",
        url: `${base_url}/visitor/send-verification-otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          phone: Number(values.phone),
          companyId: companyId,
        },
      };

      axios(reqObj)
        .then((res) => {
          navigate("/visitor-otp-verification");
          dispatch(visitorActions.setVisitorPhone(Number(values.phone)));
          localStorage.setItem("visitorPhone", values.phone);
          setLoading(true);
          dispatch(visitorActions.setOTPTime(res.data.smsTime));
          localStorage.setItem("OTPTime", res.data.smsTime);
        })
        .catch((err) => {
          console.log(err);
          formik.setFieldError(
            "phone",
            "Opps...There seems to be a server error"
          );
          setLoading(false);
        });
    },
  });

  return (
    <>
      <div className="wrapper-box">
        <div className="container">
          <div className="app-panel">
            <div className="wrapper-mid">
              <div className="logo-div">
                <img src={require("../../assets/img/images/logo.png")} />
              </div>

              <div className="text-logo">
                <h1>Digi-Pass</h1>
                <p>
                  Visitor Management <br></br>System
                </p>
              </div>
              <div className="input-panel">
                <h2>Please enter your valid Mobile Number</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group logininput">
                    <input
                      type="text"
                      className="form-control input-field"
                      placeholder="Enter Phone Number"
                      id="phone"
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />

                    <span className={classes.errMsg}>
                      {formik.touched.phone && formik.errors.phone ? (
                        <div>{formik.errors.phone}</div>
                      ) : null}
                    </span>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      value="Submit"
                      className="btn btn-primary submit-btn"
                    >
                      {isLoading ? (
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitorLogin;
