import { useContext } from "react";
import { useProSidebar } from "react-pro-sidebar";
import authContext from "../../../AuthContext/authContext";
import classes from "../Home/Home.module.css";

const Header = () => {
  const ctx = useContext(authContext);
  const { collapseSidebar, collapsed } = useProSidebar();
  return (
    <>
      <div
        className={classes.topHeader}
        style={{
          justifyContent:
            window.screen.width <= 1100 ? "space-between" : "flex-end",
          position: window.screen.width <= 1100 ? "sticky" : "static",
          top: window.screen.width <= 1100 ? "0" : "",
          zIndex: "11",
        }}
      >
        {window.screen.width <= 1100 && (
          <div className={classes.hamburger} onClick={() => collapseSidebar()}>
            <img src={require("../../assets/img/hamburger.png")} />
          </div>
        )}
        <div
          className={classes.logout}
          style={{
            display: window.screen.width <= 1100 ? "none" : "flex",
          }}
          onClick={() => ctx.logout()}
        >
          Logout
        </div>
      </div>
    </>
  );
};

export default Header;
