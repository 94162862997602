import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import Footer from "../Footer/Footer";
import classes from "./Terms.module.css";
import { convertToHTML } from "draft-convert";
import { useSelector } from "react-redux";
const base_url = process.env.REACT_APP_BASE_URL;
const Terms = () => {
  const companyData = useSelector((state) => state.visitorData.companyData);
  const navigate = useNavigate();
  const [domloaded, setDomloaded] = useState(false);
  const [terms, setTerms] = useState();

  useEffect(() => {
    axios(`${base_url}/visitor/company/terms/${companyData._id}`)
      .then((res) => {
        setTerms(res.data.terms);
        setDomloaded(true);
      })
      .catch((err) => {
        // formik.setFieldError("phone", "Invalid company Id");
        setDomloaded(true);
      });
  }, []);
  return (
    <>
      {domloaded && (
        <div className="wrapper-box">
          <div className="container">
            <div className="wrapper-mid" style={{ background: "#fff" }}>
              <div className="term-div">
                <h1 className="term-head" style={{ textAlign: "center" }}>
                  Terms & Conditions
                </h1>
                <hr className={classes.hr2} />

                <BackButton
                  path="/visitor-otp-verification"
                  className="back-btn"
                />
                <section>
                  <div
                    className="term-con-div"
                    dangerouslySetInnerHTML={{ __html: terms }}
                  />
                </section>
                <Footer footerColor={"black"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Terms;
