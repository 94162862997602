import classes from "../loginPage/Login.module.css";
import otpClass from "./otp.module.css";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
const OTPVerification = () => {
  const [otp, setOtp] = useState();
  const [isLoading, setLoading] = useState(false);
  const resetPhone = useSelector((state) => state.appData.resetPhone);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      otpCode: Number(useSelector((state) => state.visitorData.OTP)),
    },
    validationSchema: Yup.object({
      otpCode: Yup.string()
        .length(4, "Otp must have 4 digits")
        .required("Please enter your phone number"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let reqObj = {
        method: "POST",
        url: `${base_url}/visitor/verification-otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          phone: Number(resetPhone),
          // phone: visitorPhone,
          otp: Number(values.otpCode),
        },
      };

      axios(reqObj)
        .then((res) => {
          if (res.data.status == false) {
            throw new Error("Invalid OTP");
          }
          setLoading(false);
          navigate("/change-password");
        })
        .catch((err) => {
          formik.setFieldError(
            "termsCheck",
            err.toString() || "Something went wrong"
          );
          setLoading(false);
        });
    },
  });
  return (
    <div className={classes.loginConntainer}>
      <div className={classes.loginLeft}>
        <div className={classes.loginBg1}>
          <img src={require("../../assets/img/bgLogin_1.png")} />
        </div>

        <div className={classes.centerCont}>
          <img src={require("../../assets/img/Digipass_logo.png")} />
          <h1>Digi-Pass</h1>
          <p>Visitor Management</p>
          <p>System</p>
        </div>

        <div className={classes.loginBg2}>
          <img src={require("../../assets/img/bgLogin_2.png")} />
        </div>
      </div>

      <div className={classes.loginRight}>
        <div className={classes.loginBgR1}>
          <img src={require("../../assets/img/bgLoginR1.png")} />
        </div>

        <form className={classes.centerContR} onSubmit={formik.handleSubmit}>
          <h2>Please enter the OTP received on your phone number</h2>
          <OtpInput
            onChange={(num) => {
              setOtp(num);
              formik.setFieldValue("otpCode", num);
            }}
            containerStyle={`${classes.inputDiv} ${otpClass.otpDiv}`}
            inputStyle={otpClass.otpField}
            numInputs={4}
            isInputNum={true}
            onBlur={formik.handleBlur}
            value={formik.values.otpCode}
            shouldAutoFocus={true}
          />
          <span className={classes.errMsg}>
            {formik.touched.otpCode && formik.errors.otpCode ? (
              <div style={{ textAlign: "center" }}>{formik.errors.otpCode}</div>
            ) : null}
          </span>
          <button type="submit" className={classes.loginBtn}>
            {isLoading ? (
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Verify"
            )}
          </button>
        </form>

        <div className={classes.loginBgR2}>
          <div className={classes.div1}>
            <p>
              Powered by Fedicoms Pvt Ltd. <br />
              www.fedicoms.com
            </p>
          </div>
          <div className={classes.div2}>
            <img src={require("../../assets/img/bgLoginR2.png")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
