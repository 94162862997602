import AddNewLocation from "./AddNewLocation";
import Home from "../Home/Home";

const AddNewLocationWrapper = () => {
  return (
    <>
      <Home propComponent={<AddNewLocation />} />
    </>
  );
};

export default AddNewLocationWrapper;
