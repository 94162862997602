import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import authContext from "../../../AuthContext/authContext";

const PrivateRoutes = () => {
  const ctx = useContext(authContext);
  const isLoggedIn = ctx.isLoggedIn;
  return <>{isLoggedIn ? <Outlet /> : <Navigate to="/" />}</>;
};

export default PrivateRoutes;
