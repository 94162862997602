import { BarChart } from "../Chart/BarChart";
import { LineChart } from "../Chart/LineChart";
import classes from "./Dashboard.module.css";
import Hexagon from "react-hexagon";
import { useContext, useEffect } from "react";
import authContext from "../../../AuthContext/authContext";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import appActions from "../../../ReduxStore/appDataSlice";
import PieChart from "../Chart/PieChart";
import UniqueVisitor from "../Chart/UniqueVisitor";

const Dashboard = ({
  initialData,
  setCompanyId,
  setFrequency,
  companyList,
  isLoading,
}) => {
  const {
    currentMonthVisitors,
    digipassGenerated,
    digipassRejected,
    lastMonthVisitors,
    smsCount,
    visitorCount,
    companyCount,
    repeatedVisitorNumber,
    uniqueVisitorNumber,
  } = initialData;

  const ctx = useContext(authContext);
  const userType = ctx.userData.user_type;
  let showCompany = userType == "admin" || userType == "subadmin";

  // useEffect(() => {
  //   if (showCompany && companyList.length > 0) {
  //     setCompanyId(companyList[0]["_id"]);
  //   }
  // }, []);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     appActions.setBarChartPayload({ companyId: companyList[0]["_id"] })
  //   );
  //   localStorage.setItem(
  //     "barChartPayload",
  //     JSON.stringify({ companyId: companyList[0]["_id"] })
  //   );
  // }, []);

  const handleFrequency = (e) => {
    setFrequency(e.target.value);
    dispatch(appActions.setBarChartFreqency(e.target.value));
  };
  return (
    <div className={classes.dashboardCont}>
      <div className={classes.header}>
        <div>
          <h2>Dashboard</h2>
        </div>
        <div style={{ display: "flex" }}>
          {/* <select>
            <option>DF Technology</option>
          </select>
          <select>
            <option>Monthlys</option>
          </select> */}
          {showCompany && companyList.length > 0 && (
            <select
              class="form-select mr-2 mt-2"
              aria-label="Default select example"
              onChange={(e) => {
                setCompanyId(e.target.value);
                dispatch(
                  appActions.setBarChartPayload({ companyId: e.target.value })
                );
                localStorage.setItem(
                  "barChartPayload",
                  JSON.stringify({ companyId: e.target.value })
                );
              }}
            >
              <option selected value={companyList[0]["_id"]}>
                {companyList[0]["name"]}
              </option>
              {companyList.map((company, index) => {
                if (index !== 0) {
                  return <option value={company._id}>{company.name}</option>;
                }
              })}
            </select>
          )}

          <select
            class="form-select mr-2 mt-2"
            aria-label="Default select example"
            onChange={handleFrequency}
          >
            <option value={7} selected>
              7 days
            </option>
            <option value={30}>1 Month</option>
            <option value={90}>3 Months</option>
            <option value={365}>1 Year</option>
          </select>
        </div>
      </div>

      {isLoading ? (
        <section class="container">
          <div className="row">
            {showCompany && (
              <div className={`col card text-bg-primary m-3 ${classes.card}`}>
                <div className={`card-header text-center ${classes.header1}`}>
                  New Companies
                </div>
                <div
                  class="card-body d-flex justify-content-center align-items-center"
                  style={{
                    width: "100%",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  {/* <Hexagon
                    style={{
                      stroke: "#a75151",
                      strokeWidth: 100,
                    }}
                    flatTop
                    
                  ></Hexagon>

                  <span className={classes.dogContent}>
                    {companyCount || 0}
                  </span> */}

                  <div
                    className={classes.outerRing}
                    style={{ backgroundColor: "#5551a7" }}
                  >
                    <div className={classes.innerRing}>{companyCount || 0}</div>
                  </div>
                </div>
              </div>
            )}

            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header2}`}>
                Visitors
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                {/* <Hexagon
                  style={{
                    stroke: "#5551A7",
                    strokeWidth: 100,
                  }}
                  flatTop
                  viewBox="-60 -17 650 551"
                ></Hexagon> */}
                {/* <span className={classes.dogContent}>{visitorCount}</span> */}
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#57b896" }}
                >
                  <div className={classes.innerRing}>{visitorCount}</div>
                </div>
              </div>
            </div>

            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header3}`}>
                Unique Visitors
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#a75151" }}
                >
                  <div className={classes.innerRing}>{uniqueVisitorNumber}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header4}`}>
                Repeated Visitors
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#519ea7" }}
                >
                  <div className={classes.innerRing}>
                    {repeatedVisitorNumber}
                  </div>
                </div>
              </div>
            </div>

            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header5}`}>
                DigiPass Generated
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                {/* <Hexagon
                  style={{
                    stroke: "#519ea7",
                    strokeWidth: 100,
                  }}
                  flatTop
                ></Hexagon>
                <span className={classes.dogContent}>{digipassGenerated}</span> */}
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#51a75f" }}
                >
                  <div className={classes.innerRing}>{digipassGenerated}</div>
                </div>
              </div>
            </div>

            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header6}`}>
                DigiPass Rejected
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#d4442e" }}
                >
                  <div className={classes.innerRing}>{digipassRejected}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={`col  card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header7}`}>
                SMS Send
              </div>
              <div
                class="card-body d-flex justify-content-center align-items-center"
                style={{ width: "100%", margin: "auto", position: "relative" }}
              >
                {/* <Hexagon
                  style={{
                    stroke: "#51A75F",
                    strokeWidth: 100,
                  }}
                  flatTop
                  
                ></Hexagon>
                <span className={classes.dogContent}>{smsCount}</span> */}
                <div
                  className={classes.outerRing}
                  style={{ backgroundColor: "#c45ab4" }}
                >
                  <div className={classes.innerRing}>{smsCount}</div>
                </div>
              </div>
            </div>

            <div className={`col card text-bg-primary m-3 ${classes.card}`}>
              <div className={`card-header text-center ${classes.header8}`}>
                Visitor Data
              </div>
              <div
                class={`card-body  ${classes.dog6}`}
                style={{ width: "100%", margin: "auto" }}
              >
                <PieChart
                  currentMonthVisitors={currentMonthVisitors}
                  lastMonthVisitors={lastMonthVisitors}
                />
              </div>
            </div>
            <div
              className={`col card text-bg-primary m-3 ${classes.card}`}
              style={{ visibility: "hidden" }}
            ></div>
          </div>

          <div className={` ${classes.lineChart}`}>
            <div>
              <h2 className="text-center">Visitors</h2>
              <div className={classes.lineChartDiv}>
                <LineChart />
              </div>
            </div>
          </div>

          <div className={` ${classes.lineChart} ${classes.barChart}`}>
            <div>
              <h2 className="text-center">Department Wise Visitors</h2>
              <div className={classes.lineChartDiv}>
                <BarChart />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Loader centerLoader={"centerLoader"} />
      )}
    </div>
  );
};

export default Dashboard;
